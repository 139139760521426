import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { post } from '../../utils/request';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: localStorage.getItem('hasLogin') // is login ?
    };
    this.getUserInfo = this.getUserInfo.bind(this);
  }
  componentDidMount() {
    // 查看是否登录
    if (this.state.isLogin !== 'true') {
      this.getUserInfo();
    } else {
    }
  }
  componentWillUnmount() {
    this.setState = () => false;
  }
  getUserInfo() {
    // 如果是手动输入url  调用api 查看 是否登录
    post('/user/getUserInfo').then((result) => {
      let code = result.code !== 1 && result.code !== 4 && result.code !== 5;
      localStorage.setItem('hasLogin', code);
      this.setState({
        isLogin: code
      });
    });
  }
  render() {
    let { isLogin } = this.state;
    let { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLogin ? children : <Redirect to='/login' />
        }
      />
    );
  }
}
export default PrivateRoute;
