import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import styles from './index.module.css';
class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={styles.wrap}>
        {this.props.routeList.map((item, index) => (
          <Link key={item.id} to={`${item.url}`}>
            {item.pathName}
            {this.props.routeList.length - 1 === index ? '' : '> '}
          </Link>
        ))}
      </div>
    );
  }
}
export default withRouter(Breadcrumb);
