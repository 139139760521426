import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Pagination from '../../components/pagination'
import styles from './index.module.css'
import { post } from '../../utils/request'
import like from '../../images/like.png'
import unlike from '../../images/unlike.png'
import { message } from 'antd'

class Experimental extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
      selectTags: [],
      videoList: [],
      pageSize: 100,
      page: 1,
      totalNum: 0,
      year: null,
      month: null,
      hasNextPage: false
    }
  }
  componentDidMount() {
    console.log(11111111, this.state.year, this.state.month)
    const years = []
    const months = []
    const yearNow = new Date().getFullYear()
    // 年
    for (let year = 2020; year <= yearNow; year++) years.push({ name: `${year}年`, tagId: year })
    // 月
    for (let month = 1; month < 13; month++) months.push({ name: `${month}月`, tagId: month })
    this.setState({
      tags: [
        { name: '年', tags: years },
        { name: '月', tags: months }
      ]
    })
    setTimeout(() => {
      this.getVideoList(this.props.levelId2)
    }, 1000)
  }
  // 删掉tag
  delTag = (e) => {
    let delIndex = e.target.dataset.index * 1
    this.state.selectTags.splice(delIndex, 1)
    this.setState({ selectTags: this.state.selectTags }, () => {
      // let getId = []
      // this.state.selectTags.map((item) => getId.push(item.tagId * 1))
      // let num = getId.filter((item) => item > 12)
      // // 选择了年
      // if (!!num.length) {
      //   // 只有年
      //   if (getId.length === 1) {
      //     this.getVideoList(this.props.levelId2, false, getId[0])
      //   } else {
      //     // 选年月
      //     let y = getId.sort((a, b) => a - b)[1]
      //     let m = getId.sort((a, b) => a - b)[0]
      //     this.getVideoList(this.props.levelId2, false, y, m)
      //   }
      // } else {
      //   //  选择了月
      //   this.getVideoList(this.props.levelId2)
      // }

      if (!this.state.selectTags.length) {
        return this.getVideoList(this.props.levelId2)
      }
      let getId = []
      this.state.selectTags.map((item) => getId.push(item.tagId * 1))
      // 大于12 是年
      let num = getId.filter((item) => item > 12)
      // 删了月
      if (!!num.length) {
        // 只有年
        this.getVideoList(this.props.levelId2, false, getId[0])
      } else {
        //  删了年
        this.state.selectTags.splice(0, 1)
        //  选择了月
        this.getVideoList(this.props.levelId2)
      }
    })
  }
  // 选择tags
  selectTag = (e) => {
    // 选择的tag的坐标
    let index = e.target.dataset.index.split(',')
    // 选择的tag的数据
    let select = JSON.parse(e.target.dataset.tag)
    // 点击的自己
    let clickSelf = this.state.selectTags.filter((item) => item.tagId === select.tagId)
    // 点击的兄弟要把原来的删掉
    let sameFather = this.state.selectTags.filter((item) => item.fatherIndex === index[0] * 1)
    if (!sameFather.length && !clickSelf.length) {
      this.state.selectTags.push({ ...select, fatherIndex: index[0] * 1 })
    } else {
      let newArr = this.state.selectTags.filter((item) => item.fatherIndex !== index[0] * 1)
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectTags = JSON.parse(JSON.stringify(newArr))
      this.state.selectTags.push({ ...select, fatherIndex: index[0] * 1 })
    }
    this.setState({ selectTags: this.state.selectTags }, () => {
      let getId = []
      this.state.selectTags.map((item) => getId.push(item.tagId * 1))
      let num = getId.filter((item) => item > 12)
      // 选择了年
      if (!!num.length) {
        // 只有年
        if (getId.length === 1) {
          this.getVideoList(this.props.levelId2, false, getId[0])
        } else {
          this.getVideoList(this.props.levelId2, false, getId.sort((a, b) => a - b)[1], getId.sort((a, b) => a - b)[0])
        }
      } else {
        //  选择了月
        message.warning('请选择年份')
      }
    })
  }
  // 更多
  showMore = (type) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.page += 1
    this.setState({ page: this.state.page })
    this.getVideoList(this.props.levelId2, true)
  }
  // 视频列表
  getVideoList = (ids = this.props.levelId2, more = false, year, month) => {
    // 不是更多
    if (!more) this.setState({ videoList: [] })
    let { page, pageSize } = this.state
    post('/visitor/getVideoList', {
      videoTypeId: ids,
      page,
      pageSize,
      year,
      month
    }).then((result) => {
      if (result.code === 0) {
        let oldData = this.state.videoList
        this.setState({
          totalNum: result.data.totalSize,
          videoList: [...oldData, ...result.data.pageList],
          hasNextPage: result.data.hasNextPage
        })
      }
    })
  }
  // 视频点赞/取消点赞
  videoUp = (code, type, e) => {
    e.stopPropagation()
    e.preventDefault()
    post('/video/videoUp', { code, type: type * 1 ? 0 : 1 }).then((result) => {
      if (result.code === 0) {
        let newList = this.state.videoList.map((item) => {
          if (item.code === code) {
            item.isUp = !type
            item.likeNum = result.data.likeNum
          }
          return item
        })
        this.setState({ videoList: [...newList] })
      } else {
        this.props.history.push('/login')
      }
    })
  }
  addBg(name) {
    let arr = this.state.selectTags.filter((item) => item.name === name)
    return !!arr.length
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.levelId2 !== this.props.levelId2) {
      this.setState({ videoList: [] })
      this.getVideoList()
      this.setState({ selectTags: [] })
    }
  }
  render() {
    return (
      <div className={styles.wrapChild}>
        {/* tag列表 */}
        {this.state.tags.map((item, fatherI) => (
          <div key={fatherI} className={styles.filterTypes}>
            <div className={styles.dateTypes}>{item.name}</div>
            <ul className={styles.TypesBox}>
              {item.tags.map((child, childI) => (
                <li onClick={this.selectTag} key={childI} data-index={[fatherI, childI]} data-tag={JSON.stringify(child)} className={`${styles.types} ${this.addBg(child.name) && styles.selectType}`}>
                  {child.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
        {/* 已选择的tag */}
        {!!this.state.tags.length && (
          <div className={styles.filterTypes}>
            <div className={styles.dateTypes}>已选</div>
            <ul className={styles.TypesBox}>
              {this.state.selectTags.map((item, index) => (
                <li key={index} className={`${styles.types} ${styles.selectType} ${styles.selectT}`}>
                  <span>{item.name}</span>
                  <span className={styles.del} onClick={this.delTag} data-index={index}>
                    ×
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={styles.discover}>
          <div className={styles.discoverWrap}>
            <p className={styles.filterContent}>筛选后内容</p>
            <ul className={styles.discoverList}>
              {this.state.videoList.map((item, index) => (
                <li
                  key={index}
                  className={styles.discoverItem}
                  onClick={() => {
                    let hasLogin = localStorage.getItem('hasLogin')
                    // 没登陆不让看了
                    if (hasLogin === 'false') {
                      window.location.href = '/login'
                      return false
                    }
                    window.open(item.url)
                  }}
                >
                  <img className={styles.discoverImg} src={item.imgUrl} alt='' />
                  <p className={styles.discoverTitle}>{item.title}</p>
                  <div className={styles.discoverLike} onClickCapture={(e) => this.videoUp(item.code, item.isUp, e)}>
                    <img className={styles.iconLike} src={item.isUp ? like : unlike} alt='' />
                    {item.likeNum}喜欢
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Pagination list={this.state.videoList} totalNum={this.state.totalNum} hasNextPage={this.state.hasNextPage} showMore={this.showMore}></Pagination>
      </div>
    )
  }
}
export default withRouter(Experimental)
