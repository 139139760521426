import React, { Component } from 'react';
import styles from './index.module.css';
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      list: []
    };
  }
  render() {
    return (
      <div className={styles.wrap}>
        {this.props.hasNextPage && (
          <p className={styles.getMore} onClick={this.props.showMore}>
            加载更多内容
          </p>
        )}
        <p className={styles.listCount}>
          {this.props.list.length}/{this.props.totalNum}个结果
        </p>
      </div>
    );
  }
}
export default Pagination;
