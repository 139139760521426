// 环境
const ENV = process.env.NODE_ENV;
// 服务器地址
let host = null;
if (ENV === 'development') {
  // host = 'http://localhost:8090/api'; // mock js
  // host = 'https://www.shsjyjszbfwzx.cn/shjz/api'; // develop
  host = '/shjz/api'; // proxy
} else {
  host = 'https://www.shsjyjszbfwzx.cn/shjz/api';
}
export { host };
 