import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
import Breadcrumb from '../../components/breadcrumb';
// import top from '../../images/back-top.png';
import { post } from '../../utils/request';

class GuideDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem('teachId'),
      leftList: [],
      detailData: '',
      type: '',
      collect: false,
      fromCollect: window.location.href.indexOf('type') === -1,
      routeList: [
        {
          id: 1,
          url: '/',
          pathName: '首页'
        },
        {
          id: 2,
          url: '/collect/toy',
          pathName: '我的收藏'
        },
        {
          id: 3,
          url: '/teachDetail',
          pathName: '教学设备'
        }
      ]
    };
  }
  componentDidMount() {
    this.getDetail();
    let notCollect = this.props.location.search.indexOf('type') !== -1;
    if (notCollect) {
      this.state.routeList.map((item) => {
        if (item.id === 2) {
          item.url = '/typeList/typeTeachingFacility/toy?type=1';
          item.pathName = '教学设备';
        }
      });
    }
    let arr = JSON.parse(localStorage.getItem('interrelated')) || [];
    this.setState({
      leftList: [...arr]
    });
  }
  componentDidUpdate(prev) {
    if (prev.location.search !== this.props.location.search) {
      let str = this.props.location.search;
      let code = str.slice(6, str.length - 1);
      this.setState(
        {
          id: code,
          routeList: JSON.parse(localStorage.getItem('detailId')).route
        },
        () => {
          this.getDetail();
        }
      );
    }
  }
  toTop = () => {
    window.scrollTo(0, 0);
  };
  collect = () => {
    let collect = this.state.collect;
    post('/product/productCollect', {
      codes: this.state.id,
      type: collect ? 0 : 1
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          collect: !collect
        });
      }
    });
  };
  share = () => {
    alert('分享');
  };
  getDetail = () => {
    post('/visitor/getArticleDetailInfo', { code: this.state.id }).then(
      (result) => {
        if (result.code === 0) {
          if (this.state.routeList.length === 2) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.routeList[1].pathName = result.data.title;
          } else {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.routeList[2].pathName = result.data.title;
          }

          this.setState({
            detailData: result.data,
            routeList: this.state.routeList,
            collect: result.data.collect
          });
        }
      }
    );
  };
  render() {
    let { detailData } = this.state;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <div className={styles.partLeft}>
            <Breadcrumb routeList={this.state.routeList} />
            <img
              className={styles.leftFile}
              src={detailData.bigImgUrl}
              alt=''
            />
            {/* <div
              className={styles.contentBox}
              dangerouslySetInnerHTML={{ __html: detailData.content }}
            ></div> */}
          </div>
          <div className={styles.partRight}>
            <p className={styles.detailTitle}>{detailData.title}</p>
            <p className={styles.detailTime}>发布时间 ：{detailData.addTime}</p>
            <p className={styles.desc}>{detailData.desc}</p>
            <div className={styles.params}>
              <div className={styles.paramsCode}>
                <div className={styles.paramName}>产品代码</div>
                <div className={styles.paramVal}>
                  {localStorage.getItem('productCode')}
                </div>
              </div>
            </div>
            <div className={styles.btns}>
              {this.state.collect ? (
                <button className={styles.btnCollectY} onClick={this.collect}>
                  取消收藏
                </button>
              ) : (
                <button className={styles.btnCollectN} onClick={this.collect}>
                  加入收藏夹
                </button>
              )}
              {/* <button className={styles.btnShare} onClick={this.share}></button> */}
            </div>
          </div>
        </div>
        <div className={styles.bottomList}>
          <ul className={styles.listLeft}>
            <li className={styles.rT}>相关产品</li>
            {this.state.leftList.map((item, index) => (
              <li
                key={index}
                className={styles.l}
                onClick={() => {
                  this.props.history.push(
                    this.state.fromCollect
                      ? '/teachDetail'
                      : '/teachDetail?type=1'
                  );
                  localStorage.setItem('teachId', item.code);
                  localStorage.setItem('productCode', item.productCode);
                  localStorage.setItem(
                    'interrelated',
                    JSON.stringify(this.state.leftList)
                  );
                  window.location.href = this.state.fromCollect
                    ? '/teachDetail'
                    : '/teachDetail?type=1';
                }}
              >
                <div
                  style={{
                    background: `url(${item.bigImgUrl})center / cover no-repeat`
                  }}
                  className={styles.lImg}
                ></div>
                <p className={styles.lTitle}>{item.title}</p>
              </li>
            ))}
          </ul>
          <ul className={styles.listRight}>
            <li className={styles.rT}>产品信息</li>
            <li
              className={styles.r}
              dangerouslySetInnerHTML={{ __html: detailData.content }}
            ></li>
            {/* <li
              style={{
                background: `url(${detailData.bigImgUrl})center / cover no-repeat`
              }}
              className={styles.rImg}
            ></li> */}
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(GuideDetail);
