import React, { Component } from 'react';
import styles from './index.module.css';
class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val: '',
      nowId: Number, // 当前省id
      showList: false
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.hide = this.hide.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  toggleShow() {
    this.setState({ showList: !this.state.showList });
  }
  hide() {
    this.setState({ showList: false });
  }
  // 设置城市
  setValue(content) {
    console.log(content);
    this.setState({ val: content.name });
    if (this.props.type === 1) {
      let { nowId } = this.state;
      this.props.filterCity(content.id, nowId !== content.id); // 如果是省，把id传给第二个查询市
      this.setState({ nowId: content.id });
      if (this.props.resetVal) {
        this.setState({
          nowId: ''
        });
      }
    } else {
      this.props.selectRight(content.id);
      this.setState({ val: content.name });
    }
  }

  componentDidUpdate(pre) {
    if (this.props.type === 2) {
      // console.log(this.props.resetVal);
    }
  }
  render() {
    return (
      <div className={styles.wrap}>
        <input
          readOnly
          className={styles.placeholder}
          placeholder={this.props.title || '选择城市'}
          onClick={this.toggleShow}
          onBlur={this.hide}
          value={this.props.resetVal ? '' : this.state.val}
        />

        {this.state.showList && (
          <ul className={styles.selectBox}>
            {this.props.list.map((item, index) => (
              <li
                key={index}
                className={styles.options}
                onMouseDown={() =>
                  this.setValue({
                    name: item.provinceName || item.cityName,
                    id: item.provinceId || item.cityId
                  })
                }
              >
                {item.provinceName || item.cityName}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
export default Select;
