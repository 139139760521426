import React, { Component } from 'react';
import './App.css';
import './config';
// import { DevicePixelRatio } from './utils/utils';
import Layout from './views/layout';
class App extends Component {
  componentDidMount() {
    // new DevicePixelRatio().init();
  }
  render() {
    return <Layout />;
  }
}

export default App;
