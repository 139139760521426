import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
import Breadcrumb from '../../components/breadcrumb';
import CorrelationLink from '../../components/correlationLink';
import CommonFun from '../../components/commonFun';
// import top from '../../images/back-top.png';
import { post } from '../../utils/request';

class GuideDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: JSON.parse(localStorage.getItem('detailId')).code,
      detailData: '',
      articleList: [],
      type: '',
      routeList: JSON.parse(localStorage.getItem('detailId')).route
    };
  }
  componentDidMount() {
    this.getDetail();
    let str = this.props.location.search;
    let type = str.slice(str.length - 1);
    this.setState({
      type
    });
    this.getArticleList(type * 1);
  }
  componentDidUpdate(prev) {
    if (prev.location.search !== this.props.location.search) {
      let str = this.props.location.search;
      let code = str.slice(6, str.length - 1);
      this.setState(
        {
          id: code,
          routeList: JSON.parse(localStorage.getItem('detailId')).route
        },
        () => {
          this.getDetail();
        }
      );
    }
  }
  toTop = () => {
    window.scrollTo(0, 0);
  };
  getArticleList = (type) => {
    post('/visitor/getArticleList', {
      type, // 文章列表类型
      page: 1,
      pageSize: 3
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          articleList: result.data.pageList.slice(0, 3)
        });
      }
    });
  };
  getDetail = () => {
    post('/visitor/getArticleDetailInfo', { code: this.state.id }).then(
      (result) => {
        if (result.code === 0) {
          if (this.state.routeList.length === 2) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.routeList[1].pathName = result.data.title;
          } else {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.routeList[2].pathName = result.data.title;
          }

          this.setState({
            detailData: result.data,
            routeList: this.state.routeList
          });
        }
      }
    );
  };
  render() {
    let { detailData } = this.state;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <div className={styles.partLeft}>
            <Breadcrumb routeList={this.state.routeList} />
            <p className={styles.detailTitle}>{detailData.title}</p>
            <p className={styles.detailTime}>发布时间 ：{detailData.addTime}</p>
            {/* <img
              className={styles.leftFile}
              src={detailData.bigImgUrl}
              alt=''
            /> */}
            <div
              className={styles.contentBox}
              dangerouslySetInnerHTML={{ __html: detailData.content }}
            ></div>
          </div>
          <div className={styles.partRight}>
            {this.state.articleList.length > 0 && (
              <CorrelationLink
                type={this.state.type}
                articleList={this.state.articleList}
              />
            )}
            <CommonFun />
          </div>
          {/* <img className={styles.toTop} src={top} onClick={this.toTop} alt='' /> */}
        </div>
      </div>
    );
  }
}
export default withRouter(GuideDetail);
