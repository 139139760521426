import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  toDetail = (code) => {
    let hasLogin = localStorage.getItem('hasLogin');
    // 没登陆不让看了
    if (hasLogin === 'false') {
      window.location.href = '/login';
      return false;
    }
    localStorage.setItem(
      'detailId',
      JSON.stringify({
        code: code,
        route: [
          {
            id: 1,
            url: '/',
            pathName: '首页'
          },
          {
            id: 2,
            url: `guideDetail?name=${code}0`,
            pathName: ''
          }
        ]
      })
    );
    this.props.history.push(`/guideDetail?name=${code}0`);
    this.props.clearList();
  };
  render() {
    return (
      <div className={styles.wrap}>
        <ul className={styles.searchList}>
          {this.props.searchList.map((item, index) => (
            <li key={index} onClick={() => this.toDetail(item.code)}>
              {item.desc}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
export default withRouter(Search);
