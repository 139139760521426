import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { post } from '../../utils/request';
import { verifyPhone } from '../../utils/utils';
import styles from './index.module.css';
import wechat from '../../images/icon-wechat.png';
import qq from '../../images/icon-qq.png';
import alipay from '../../images/icon-alipay.png';
import tel from '../../images/icon-tel.png';
import yzm from '../../images/icon-yzm.png';
import logoBg from '../../images/login-bg.png';
import { message } from 'antd';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
class Login extends Component {
  state = {
    phone: '', //your tel
    smsCode: '', //
    warnPhone: false, // show  red color waring
    warnCode: false, // show  red color waring
    countdown: 60,
    buttonType: 0 // 验证码后面的按钮  0获取   1倒计时 2重新获取
  };
  // 手机号失焦
  handleBlur = (e) => {
    if (!this.state.phone) {
      this.setState({
        warnPhone: true
      });
    }
  };
  // 验证码失焦
  blurCode = (e) => {
    if (!this.state.smsCode) {
      this.setState({
        warnCode: true
      });
    }
  };
  // 验证码失焦
  focusCode = (e) => {
    if (!this.state.smsCode) {
      this.setState({
        warnCode: false
      });
    }
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState({
      phone: val,
      warnPhone: !verifyPhone(val)
    });
  };

  handleCode = (e) => {
    let val = e.target.value;
    this.setState({
      smsCode: val
    });
  };
  // 倒计时
  countdown = (callback) => {
    clearInterval(this.timer);
    let { countdown } = this.state;
    this.timer = setInterval(() => {
      if (countdown === 0) {
        this.setState({
          countdown: 60,
          buttonType: 2
        });
        clearInterval(this.timer);
      } else {
        this.setState({
          countdown: countdown--
        });
      }
    }, 1000);
  };
  // 获取验证码
  getCode = () => {
    this.setState({
      warnPhone: !verifyPhone(this.state.phone)
    });
    if (verifyPhone(this.state.phone)) {
      this.setState({ buttonType: 1 });
      // 开始倒计时
      this.countdown();
      console.log('获取验证码成功');
      this.doGetCode();
    }
  };
  doGetCode() {
    post('/visitor/sendSmsCode', { phone: this.state.phone, type: 2 }).then(
      (result) => {
        if (result.code === 0) {
          message.success({
            content: '验证码发送' + result.msg,
            className: 'custom-class',
            style: {
              marginTop: '20vh'
            }
          });
        }
      }
    );
  }
  // login
  doLogin = () => {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { phone, smsCode } = this.state;
    if (phone && smsCode) {
      post('/visitor/login', { phone, smsCode }).then((result) => {
        if (result.code === 0) {
          let nowTimestamp = new Date().getTime() + 86400000 * 7;
          localStorage.setItem(
            'token',
            `${
              result.data.userToken || document.cookie.split('userToken=')[1]
            },${nowTimestamp}`
          );
          localStorage.setItem('avatar', result.data.avatarUrl);
          localStorage.setItem(
            'info',
            result.data.userName + ',' + result.data.phone
          );
          this.props.dispatch({
            type: 'AVATAR',
            value: result.data.avatarUrl
          });
          message.success({
            content: '登录成功',
            className: 'custom-class',
            style: {
              marginTop: '20vh'
            }
          });
          this.props.history.replace(from);
        }
      });
    } else {
      this.setState({ warnPhone: !verifyPhone(phone) });
      this.setState({ warnCode: !smsCode });
    }
  };

  // 去注册
  toRegister = () => {
    this.props.history.push('register');
  };
  // 第三方登录
  loginThird = (third) => {
    let url = '';
    switch (third) {
      case 'wechat':
        url =
          'https://open.weixin.qq.com/connect/qrconnect?appid=wx35771d4dd3c8d35a&redirect_uri=https://www.shsjyjszbfwzx.cn/shjz%2Fapi%2Fthird%2Foauth_callback&response_type=code&scope=snsapi_login&state=e3R5cGU6MSx1cmw6Ii9iaW5kUGhvbmU/dHlwZT13ZWNoYXQifQ==#wechat_redirect';
        break;
      case 'QQ':
        url =
          'https://graph.qq.com/oauth2.0/authorize?client_id=101963089&response_type=code&redirect_uri=https%3A%2F%2Fwww.shsjyjszbfwzx.cn%2Fshjz%2Fapi%2Fthird%2Foauth_callback&state=e3R5cGU6Myx1cmw6Ii9iaW5kUGhvbmU/dHlwZT1RUSJ9';
        break;
      default:
        url =
          'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002153645266&scope=auth_user&redirect_uri=https%3A%2F%2Fwww.shsjyjszbfwzx.cn%2Fshjz%2Fapi%2Fthird%2Foauth_callback&state=e3R5cGU6Mix1cmw6Ii9iaW5kUGhvbmU/dHlwZT1hbGlwYXkifQ==';
        break;
    }
    window.location.href = url;
    localStorage.setItem('type', third);
    console.log(`${third}登录`);
  };
  componentDidMount() {}
  componentWillUnmount() {
    this.setState = () => false;
  }
  render() {
    let flag = localStorage.getItem('hasLogin') === 'true';
    return flag ? (
      <Redirect to='/'></Redirect>
    ) : (
      <div className={styles.wrapChild}>
        <div className={styles.a}></div>
        <div className={styles.inputBox}>
          <p className={styles.inputTitle}>用户登录</p>
          <div className={styles.inputArea}>
            <img className={styles.iptIcon} src={tel} alt='' />
            <input
              className={styles.telIpt}
              maxLength='11'
              placeholder='请输入手机号'
              type='tel'
              value={this.state.phone}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {this.state.warnPhone ? (
              <span className={styles.warning}>请输入正确的手机号</span>
            ) : (
              ''
            )}
          </div>
          <div className={styles.inputArea}>
            <img className={styles.iptIcon} src={yzm} alt='' />
            <input
              maxLength='6'
              className={`${styles.yzmIpt}`}
              placeholder='请输入验证码'
              type='tel'
              value={this.state.smsCode}
              onChange={this.handleCode}
              onBlur={this.blurCode}
              onFocus={this.focusCode}
            />
            {this.state.warnCode ? (
              <p className={styles.warning}>请输入验证码</p>
            ) : (
              ''
            )}
            {this.state.buttonType === 0 ? (
              <button className={styles.getYzm} onClick={this.getCode}>
                获取验证码
              </button>
            ) : this.state.buttonType === 1 ? (
              <button className={styles.getYzm}>
                {this.state.countdown}秒后可重发
              </button>
            ) : (
              <button
                className={styles.getYzm}
                style={{ fontSize: 14 }}
                onClick={this.getCode}
              >
                重新获取验证码
              </button>
            )}
          </div>
          <button onClick={this.doLogin} className={styles.doLogin}>
            登&nbsp;&nbsp;&nbsp;录
          </button>
          <p className={styles.otherTitle}>其他方式登录</p>
          <ul className={styles.otherLoginType}>
            <li
              className={styles.type}
              onClick={() => this.loginThird('wechat')}
            >
              <img className={styles.loginIcon} src={wechat} alt='' />
              <span>微信</span>
            </li>
            <li className={styles.type} onClick={() => this.loginThird('QQ')}>
              <img className={styles.loginIcon} src={qq} alt='' />
              <span>QQ</span>
            </li>
            <li
              className={styles.type}
              onClick={() => this.loginThird('alipay')}
            >
              <img className={styles.loginIcon} src={alipay} alt='' />
              <span>支付宝</span>
            </li>
          </ul>
          <div className={styles.toRegister}>
            还没有账号？
            <span onClick={this.toRegister} className={styles.toRegisterTitle}>
              立即注册
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(Login);
