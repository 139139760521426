import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
import header from '../../images/info-header.png';
import { post, postForm } from '../../utils/request';
import iconEnterpriseName from '../../images/info-compony.png';
import { connect } from 'react-redux';
import { message } from 'antd';
class EditInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canEdit: -1,
      userName: '',
      unitName: '',
      avatarUrl: '',
      suggest: '',
      avatarFile: '',
      latestVersion: '0.0.0',
      oldName: ''
    };
    this.changeEdit = this.changeEdit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.subSuggest = this.subSuggest.bind(this);
    this.selectImg = this.selectImg.bind(this);
  }
  //  if or not edit
  changeEdit(e) {
    this.setState(
      {
        canEdit: e
      },
      () => {
        if (e === 1) {
          this.setState({
            oldName: this.textInput1.value
          });
          this.textInput1.focus();
        } else {
          this.textInput2.focus();
        }
      }
    );
  }
  getUserInfo() {
    // 如果是手动输入url  调用api 查看 是否登录
    post('/user/getUserInfo').then((result) => {
      if (result.code === 0) {
        let { avatarUrl, userName, unitName } = result.data;
        this.setState({
          userName,
          unitName,
          avatarUrl
        });
      }
    });
  }
  // version info
  getVersionInfo() {
    post('/visitor/getVersionInfo').then((result) => {
      if (result.code === 0) {
        let { latestVersion } = result.data;
        this.setState({
          latestVersion
        });
      }
    });
  }
  // version info
  updateHeader() {
    let formData = new FormData();
    formData.append('userName', this.state.userName);
    formData.append('file', this.state.headerFile);
    postForm('/user/saveUserInfo', formData).then((result) => {
      if (result.code === 0) {
        this.props.dispatch({
          type: 'AVATAR',
          value: result.data.avatarUrl
        });
        localStorage.setItem('avatar', result.data.avatarUrl);
      }
    });
  }

  updateInfo() {
    let formData = new FormData();
    formData.append('userName', this.state.userName);
    postForm('/user/saveUserInfo', formData).then((result) => {
      let oldName = localStorage.getItem('info').split(',');
      oldName.splice(0, 1, result.data.userName);
      let newName = oldName.join(',');
      localStorage.setItem('info', newName);
      message.success({
        content: '修改' + result.msg,
        className: 'custom-class',
        style: {
          marginTop: '20vh'
        }
      });
    });
  }
  // update user info
  handleBlur() {
    this.setState({
      canEdit: -1
    });
    if (this.state.oldName !== this.state.userName) {
      this.updateInfo();
    }
  }
  handleChange(e) {
    if (e === 1) {
      this.setState({
        userName: this.textInput1.value
      });
    } else if (e === 2) {
      this.setState({
        unitName: this.textInput2.value
      });
    } else {
      this.setState({
        suggest: this.textInput3.value
      });
    }
  }
  subSuggest(e) {
    if (!!this.textInput3.value) {
      post('/user/feedback', { content: this.textInput3.value }).then(
        (result) => {
          if (result.code === 0) {
            this.textInput3.value = '';
            message.success({
              content: '提交成功',
              className: 'custom-class',
              style: {
                marginTop: '20vh'
              }
            });
          }
        }
      );
    }
  }
  // change  img
  selectImg() {
    let url = this.inputHeader.files[0];
    var reader = new FileReader(); //新建一个FileReader
    reader.readAsDataURL(url); //读取文件
    reader.onload = (evt) => {
      //读取完文件之后会回来这里
      var avatarFile = evt.target.result; // 读取文件内容
      this.setState({
        avatarFile,
        headerFile: url,
        avatarUrl: avatarFile
      });
      this.updateHeader();
    };
  }
  componentDidMount() {
    this.getUserInfo();
    this.getVersionInfo();
  }
  render() {
    // let { path } = this.props.match;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <p className={styles.titleBox}>个人信息</p>
          <div className={styles.avatarBox}>
            <img
              className={styles.infoAvatar}
              src={this.state.avatarUrl}
              alt=''
            />
            <input
              alt='选择头像'
              capture
              type='file'
              onChange={this.selectImg}
              value=''
              ref={(input) => (this.inputHeader = input)}
              className={styles.btnUpload}
            />
          </div>

          <div className={styles.changeInfoBox}>
            <img className={styles.icon} src={header} alt='' />
            <input
              maxLength='20'
              disabled={this.state.canEdit !== 1}
              className={styles.ipt}
              type='text'
              ref={(input) => (this.textInput1 = input)}
              onChange={() => this.handleChange(1)}
              value={this.state.userName}
              onBlur={this.handleBlur}
              placeholder='用户名称'
            />
            <p className={styles.btn} onClick={() => this.changeEdit(1)}>
              修改
            </p>
          </div>
          <div className={styles.changeInfoBox}>
            <img className={styles.icon} src={iconEnterpriseName} alt='' />
            <input
              ref={(input) => (this.textInput2 = input)}
              disabled={this.state.canEdit !== 2}
              className={styles.ipt}
              type='text'
              onChange={() => this.handleChange(2)}
              value={this.state.unitName}
              onBlur={this.handleBlur}
              placeholder='企业名称'
            />
            {/* <p className={styles.btn} onClick={() => this.changeEdit(2)}>
              修改
            </p> */}
          </div>
          <p className={styles.titleBox}>设置</p>
          <p className={styles.version}>
            版本信息
            <span className={styles.v}>{this.state.latestVersion}</span>
          </p>
          <div className={styles.suggest}>
            <div className={styles.suggestT}>意见反馈</div>
            <textarea
              className={styles.iptArea}
              ref={(input) => (this.textInput3 = input)}
              placeholder='欢迎您提出宝贵的建议，我们将用心改进'
              name=''
              id=''
              cols='30'
              rows='10'
              onChange={() => this.handleChange(3)}
            ></textarea>
            <button className={styles.btnSub} onClick={this.subSuggest}>
              提交
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(withRouter(EditInfo));
