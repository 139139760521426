import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import topListBg from '../../images/toplistbg.png';
// import baoxiu from '../../images/baoxiu.png';
import styles from './index.module.css';
import md5 from 'js-md5';

class TypeMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    };
  }
  componentDidMount() {
    this.props.changeBg(topListBg);
    let name = localStorage.getItem('info')
      ? localStorage.getItem('info').split(',')
      : '';
    if (!name) return;
    let md5Url = `mobile=${name[1]}&name=${name[0]}&key=892006250b4c09247ec02edce69f6a3c`;
    let md5Sign = md5(md5Url).toUpperCase();
    let url = `https://app.shrpfc.com?mobile=${name[1]}&name=${encodeURI(name[0])}&sign=${md5Sign}&sign_type=MD5`;
    this.setState({ url }, () => {
      console.log('====================================');
      console.log(url);
      console.log('====================================');
      window.open(url);
    });
  }
  render() {
    return (
      <div className={styles.wrapChild}>
        {/* <p>请用微信扫描二维码 打开运维服务</p>
        <img className={styles.repair} src={baoxiu} alt='' /> */}
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          className={styles.frame}
          src={this.state.url}
          frameBorder='0'
        ></iframe>
      </div>
    );
  }
}
export default withRouter(TypeMaintenance);
