import axios from 'axios';
import { host } from '../config';
import { getToken } from '../utils/utils';
import { message } from 'antd';
import 'antd/dist/antd.css';
const instance = axios.create({
  baseURL: host,
  headers: {
    'user-deviceId': 'web',
    'client-type': 'web',
    'client-version': '1.0.0',
    'user-token': ''
  },
  timeout: 4000
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    config.headers['user-token'] = getToken();
    if (config.url.url === '/user/saveUserInfo') {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    // Do something before request is sent
    return config;
  },
  (error) => {
    console.error(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

//  返回拦截
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    // -1	系统繁忙 0请求成功 1	未登录 2	在其他端登录 3	设备号deviceId为空 4	登录token无效或已过期 5	签名错误
    // 请求成功
    if (response && response.status === 200) {
      switch (response.data.code * 1) {
        case 0:
          // return response.data;
          break;
        case 1:
          break;
        case 4:
          message.warning({
            content: response.data.msg,
            className: 'custom-class',
            style: {
              marginTop: '25vh'
            }
          });
          console.log(response.data.msg);
          let isLogin = window.location.pathname === '/login';
          if (!isLogin) {
            setTimeout(() => {
              window.location.href = '/login';
            }, 1000);
          }
          break;
        case -1:
        case 2:
        case 3:
        case 5:
          message.warning({
            content: response.data.msg,
            className: 'custom-class',
            style: {
              marginTop: '25vh'
            }
          });
          break;
        default:
          // success request
          message.warning({
            content: response.data.msg,
            className: 'custom-class',
            style: {
              marginTop: '25vh'
            }
          });
          break;
      }
      return response.data;
    }
  },
  (error) => {
    console.error(error);
    // Do something with response error
    return Promise.reject(error);
  }
);
const get = (url, data) => {
  if (!url) return;
  return instance({
    url,
    method: 'GET',
    params: data
  });
};
const post = (url, data) => {
  if (!url) return;
  return instance({
    url,
    method: 'POST',
    data
  });
};
const postForm = (url, formData) => {
  if (!url) return;
  return instance({
    method: 'post',
    url: url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export { get, post, postForm };
