import React, { Component } from 'react';
import { NavLink, Route, Redirect, Switch } from 'react-router-dom';
import { post } from '../../utils/request';
import { verifyPhone } from '../../utils/utils';
import styles from './index.module.css';
import Select from '../../components/select';
import yzm from '../../images/icon-yzm.png';
// import MyInput from '../../components/myInput';
import search from '../../images/icon-search.png';
import { message } from 'antd';
import 'antd/dist/antd.css';
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: '', // 省
      provinceName: '', // 省的名字
      cityId: '', // 市
      userName: '', // 姓名
      unitName: '', // 单位名称
      unitId: Number, // 单位id
      userType: 1, // 用户类型
      phone: '', //your tel
      smsCode: '', //
      nowType: 0, // 当前type 0:个人    1:企业
      warnProvince: false,
      warnCity: false,
      warnUint: false, // show  red color waring
      warnName: false, // show  red color waring
      warnPhone: false, // show  red color waring
      warnCode: false, // show  red color waring
      codeWarning: false, // show  red color waring
      countdown: 60, // 倒计时
      buttonType: 0, // 获取验证码的按钮变化情况
      provinceList: [],
      cityList: [],
      unitList: [],
      resetVal: false, // 是否重置区县  更换省份后要重置city
      showPop: false,
      hideUint: false
    };
    this.valueRefSearch = React.createRef();
    this.valueRefUnit = React.createRef();
    this.valueRefName = React.createRef();
    this.valueRefPhone = React.createRef();
    this.valueRefCode = React.createRef();
  }

  // 获取城市列表
  getCityList = () => {
    post('/visitor/getLocationList').then((result) => {
      if (result.code === 0) {
        this.setState({ provinceList: result.data });
      }
    });
  };
  // 过滤县区
  filterCity = (provinceId, resetVal) => {
    // console.log(resetVal ? '改变了省' : '选择了省');
    let city = this.state.provinceList.filter(
      (item) => item.provinceId === provinceId
    );
    if (city.length && city[0].provinceName === '上海市') {
      // 隐藏单位
      this.setState({
        hideUint: true
      });
    } else {
      // 显示单位
      this.setState({
        hideUint: false,
        unitName: ''
      });
    }
    this.setState({
      provinceName: city[0].provinceName,
      unitId:'',
      unitName:'',
      cityId: '',
      resetVal: true,
      provinceId,
      cityList: city[0].cityList
    });
  };
  // 重置县区
  selectRight = (cityId) => {
    this.setState({
      cityId,
      unitName:'',
      resetVal: false
    });
  };

  // 获取单位
  getUnitList() {
    post('/visitor/getUnitList', {
      unitName: '',
      unitFirstLetter: '',
      unitId: '',
      cityId: this.state.cityId
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          unitList: result.data
        });
      }
    });
  }
  // 去注册
  toLogin = () => {
    this.props.history.push('login');
  };
  // 输入单位
  handleUnit = () => {
    const unitName = this.valueRefUnit.current.value;
    if (unitName === '' || unitName === null) {
      this.setState({
        unitId: ''
      });
    }
    this.setState({
      unitName
    });
  };
  // 显示单位
  bindFocus = () => {
    this.setState({
      showPop: true
    });
    // 获取单位列表
    this.getUnitList();
  };
  // 输入姓名
  handleName = () => {
    const userName = this.valueRefName.current.value;
    this.setState({
      userName
    });
  };
  // 输入手机号
  handlePhone = () => {
    const phone = this.valueRefPhone.current.value;
    this.setState({
      phone: phone * 1 === 0 ? '' : phone * 1,
      warnPhone: !verifyPhone(phone * 1)
    });
    if (isNaN(phone * 1)) {
      this.setState({
        phone: ''
      });
    }
  };
  // 输入code
  handleCode = () => {
    const smsCode = this.valueRefCode.current.value;
    this.setState({
      smsCode
    });
  };
  // 倒计时
  countdown = (callback) => {
    clearInterval(this.timer);
    let { countdown } = this.state;
    this.timer = setInterval(() => {
      if (countdown === 0) {
        this.setState({
          countdown: 60,
          buttonType: 2
        });
        clearInterval(this.timer);
      } else {
        this.setState({
          countdown: countdown--
        });
      }
    }, 1000);
  };
  // 验证码聚焦
  focusCode = (e) => {
    if (!this.state.smsCode) {
      this.setState({
        codeWarning: false
      });
    }
  };
  // 校验手机号
  verifyPhone(phone) {
    if (!phone) return false;
    let reg =
      /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
    if (reg.test(phone)) {
      return true;
    } else {
      return false;
    }
  }
  // 获取验证码
  getCode = () => {
    if (verifyPhone(this.state.phone)) {
      this.setState({
        warnPhone: false,
        buttonType: 1
      });
      // 开始倒计时
      this.countdown();
      this.doGetCode();
    } else {
      this.setState({
        warnPhone: true,
        phone: ''
      });
    }
  };
  doGetCode() {
    post('/visitor/sendSmsCode', { phone: this.state.phone, type: 1 }).then(
      (result) => {
        if (result.code === 0) {
          message.success({
            content: '验证码发送成功',
            className: 'custom-class',
            style: {
              marginTop: '20vh'
            }
          });
        }
      }
    );
  }
  // doRegister
  doRegister = () => {
    let { userName, unitName, cityId, provinceId, unitId, phone, smsCode } =
      this.state;
    // 企业
    if (this.state.nowType) {
      if (
        (userName && unitName && unitId && verifyPhone(phone) && smsCode) ||
        (userName && unitName && verifyPhone(phone) && smsCode)
      ) {
        post('/visitor/register', {
          userName,
          unitName,
          unitId,
          phone,
          smsCode,
          userType: 2
        }).then((result) => {
          if (result.code === 0) {
            window.location.href = '/';
          }
        });
      } else {
        this.setState({ warnUint: !unitName });
        this.setState({ warnName: !userName });
        this.setState({ warnPhone: !verifyPhone(phone) });
        this.setState({ warnCode: !smsCode });
      }
    } else {
      // 如果是上海的才有单位名
      if (this.state.provinceName === '上海市') {
        if (
          (userName &&
            unitName &&
            cityId &&
            provinceId &&
            unitId &&
            verifyPhone(this.state.phone) &&
            smsCode) ||
          (userName &&
            unitName &&
            cityId &&
            provinceId &&
            verifyPhone(this.state.phone) &&
            smsCode)
        ) {
          post('/visitor/register', {
            userName,
            unitName,
            cityId,
            provinceId,
            unitId,
            phone,
            smsCode,
            userType: 1
          }).then((result) => {
            if (result.code === 0) {
              window.location.href = '/';
            }
          });
        } else {
          this.setState({ warnProvince: !provinceId });
          this.setState({ warnCity: !cityId });
          this.setState({ warnUint: !unitName });
          this.setState({ warnName: !userName });
          this.setState({ warnPhone: !verifyPhone(phone) });
          this.setState({ warnCode: !smsCode });
        }
      } else {
        if (
          userName &&
          cityId &&
          provinceId &&
          verifyPhone(this.state.phone) &&
          smsCode
        ) {
          post('/visitor/register', {
            userName,
            unitName,
            cityId,
            provinceId,
            unitId,
            phone,
            smsCode,
            userType: 1
          }).then((result) => {
            if (result.code === 0) {
              window.location.href = '/';
            }
          });
        } else {
          this.setState({ warnProvince: !provinceId });
          this.setState({ warnCity: !cityId });
          this.setState({ warnUint: !unitName });
          this.setState({ warnName: !userName });
          this.setState({ warnPhone: !verifyPhone(phone) });
          this.setState({ warnCode: !smsCode });
        }
      }
    }
  };
  hidePop = () => {
    this.setState({
      showPop: false
    });
  };
  changeSearch = (w) => {
    const value = this.valueRefSearch.current.value;
    if (!value) {
      this.getUnitList();
    }
    this.setState({
      searchText: value
    });
  };
  setUnit = (e) => {
    let unitId = e.target.dataset.unitid;
    let unitName = e.target.dataset.unitname;
    this.setState({
      unitName,
      unitId,
      showPop: false
    });
  };
  searching = (w) => {
    let arr = this.state.unitList.filter(
      (item) => item.unitName.indexOf(this.state.searchText) !== -1
    );
    this.setState({
      unitList: [...arr]
    });
  };
  componentDidMount() {
    let refresh = this.props.location.pathname.includes('compony');
    this.setState({
      nowType: refresh ? 1 : 0
    });
    // 获取城市列表
    this.getCityList();
  }
  componentDidUpdate(prevProps, state) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        warnProvince: false,
        warnCity: false,
        warnUint: false,
        warnName: false,
        warnPhone: false,
        warnCode: false,
        hideUint: false
      });

      let url = this.props.location.pathname;
      if (url.includes('compony')) {
        this.setState({
          nowType: 1
        });
        // console.log('企业');
      } else {
        this.setState({
          nowType: 0
        });
        // console.log('个人');
      }
    }
  }
  render() {
    let { path } = this.props.match;
    let flag = localStorage.getItem('hasLogin') === 'true';
    return flag ? (
      <Redirect to='/'></Redirect>
    ) : (
      <div className={styles.wrapChild}>
        <div className={styles.inputBox}>
          <div className={styles.tabBox}>
            <NavLink
              exact
              className={styles.registerType}
              activeClassName={styles.selected}
              to={path}
            >
              教育单位注册
            </NavLink>
            <NavLink
              activeClassName={styles.selected}
              className={styles.registerType}
              to={`${path}/compony`}
            >
              企业用户注册
            </NavLink>
          </div>
          <Switch>
            {/* 教育单位 */}
            <Route exact path={path}>
              <p className={styles.selectCityTitle}>请选择省份与区县</p>
              {/* 选择城市 */}
              <div className={styles.inputCity}>
                <div className={styles.citySelectBox}>
                  <Select
                    type={1}
                    list={this.state.provinceList}
                    title='所在省份'
                    filterCity={this.filterCity}
                  ></Select>
                  {this.state.warnProvince && !this.state.provinceId ? (
                    <p className={styles.warning}>请选择省份</p>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.citySelectBox}>
                  <Select
                    type={2}
                    resetVal={this.state.resetVal}
                    list={this.state.cityList}
                    title='所在县区'
                    selectRight={this.selectRight}
                  ></Select>
                  {this.state.warnCity && !this.state.cityId ? (
                    <p className={styles.warning}>请选择区县</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* 单位名称 */}
              {this.state.hideUint && (
                <div className={styles.inputArea}>
                  <input
                    ref={this.valueRefUnit}
                    className={styles.telIpt}
                    maxLength='20'
                    placeholder='请输入单位名称'
                    type='text'
                    value={this.state.unitName}
                    onChange={this.handleUnit}
                  ></input>
                  {this.state.provinceId && this.state.cityId && (
                    <div className={styles.selectUnit} onClick={this.bindFocus}>
                      选择单位
                    </div>
                  )}
                  {this.state.warnUint && !this.state.unitName ? (
                    <p className={styles.warning}>请输入单位名称</p>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {/* 姓名 */}
              <div className={styles.inputArea}>
                <input
                  ref={this.valueRefName}
                  className={styles.telIpt}
                  maxLength='10'
                  placeholder='请输入姓名'
                  type='text'
                  value={this.state.userName}
                  onChange={this.handleName}
                ></input>
                {this.state.warnName && !this.state.userName ? (
                  <p className={styles.warning}>请输入姓名</p>
                ) : (
                  ''
                )}
              </div>
              {/* 手机号 */}
              <div className={styles.inputArea}>
                <input
                  ref={this.valueRefPhone}
                  className={styles.telIpt}
                  maxLength='11'
                  placeholder='请输入手机号'
                  type='text'
                  value={this.state.phone}
                  onChange={this.handlePhone}
                ></input>
                {this.state.warnPhone ? (
                  <p className={styles.warning}>请输入正确的手机号</p>
                ) : (
                  ''
                )}
              </div>
              {/* 验证码 */}
              <div className={styles.inputArea}>
                <img className={styles.iptIcon} src={yzm} alt='' />
                <input
                  ref={this.valueRefCode}
                  maxLength='6'
                  className={styles.yzmIpt}
                  placeholder='请输入验证码'
                  type='text'
                  value={this.state.smsCode}
                  onChange={this.handleCode}
                />
                {this.state.buttonType === 0 ? (
                  <button className={styles.getYzm} onClick={this.getCode}>
                    获取验证码
                  </button>
                ) : this.state.buttonType === 1 ? (
                  <button className={styles.getYzm}>
                    {this.state.countdown}秒后可重发
                  </button>
                ) : (
                  <button
                    className={styles.getYzm}
                    style={{ fontSize: 14 }}
                    onClick={this.getCode}
                  >
                    重新获取验证码
                  </button>
                )}
                {this.state.warnCode && !this.state.smsCode ? (
                  <p className={styles.warning}>请输入验证码</p>
                ) : (
                  ''
                )}
              </div>
            </Route>
            {/* 企业 */}
            <Route exact path={`${path}/compony`}>
              {/* 单位名称 */}
              <div className={styles.inputArea}>
                <input
                  ref={this.valueRefUnit}
                  className={styles.telIpt}
                  maxLength='21'
                  placeholder='请输入单位名称'
                  type='text'
                  value={this.state.unitName}
                  onChange={this.handleUnit}
                ></input>
                {this.state.warnUint && !this.state.unitName ? (
                  <p className={styles.warning}>请输入单位名称</p>
                ) : (
                  ''
                )}
              </div>
              {/* 姓名 */}
              <div className={styles.inputArea}>
                <input
                  ref={this.valueRefName}
                  className={styles.telIpt}
                  maxLength='10'
                  placeholder='请输入姓名'
                  type='text'
                  value={this.state.userName}
                  onChange={this.handleName}
                ></input>
                {this.state.warnName && !this.state.userName ? (
                  <p className={styles.warning}>请输入姓名</p>
                ) : (
                  ''
                )}
              </div>
              {/* 手机号 */}
              <div className={styles.inputArea}>
                <input
                  ref={this.valueRefPhone}
                  className={styles.telIpt}
                  maxLength='11'
                  placeholder='请输入手机号'
                  type='text'
                  value={this.state.phone}
                  onChange={this.handlePhone}
                ></input>
                {this.state.warnPhone ? (
                  <p className={styles.warning}>请输入正确的手机号</p>
                ) : (
                  ''
                )}
              </div>
              {/* 验证码 */}
              <div className={styles.inputArea}>
                <img className={styles.iptIcon} src={yzm} alt='' />
                <input
                  ref={this.valueRefCode}
                  maxLength='6'
                  className={`${styles.yzmIpt} ${
                    this.state.warnCode && !this.state.smsCode
                      ? styles.waringClass
                      : ''
                  }`}
                  placeholder='请输入验证码'
                  type='text'
                  value={this.state.smsCode}
                  onChange={this.handleCode}
                />
                {this.state.buttonType === 0 ? (
                  <button className={styles.getYzm} onClick={this.getCode}>
                    获取验证码
                  </button>
                ) : this.state.buttonType === 1 ? (
                  <button className={styles.getYzm}>
                    {this.state.countdown}秒后可重发
                  </button>
                ) : (
                  <button
                    className={styles.getYzm}
                    style={{ fontSize: 14 }}
                    onClick={this.getCode}
                  >
                    重新获取验证码
                  </button>
                )}
                {this.state.warnCode && !this.state.smsCode ? (
                  <p className={styles.warning}>请输入验证码</p>
                ) : (
                  ''
                )}
              </div>
            </Route>
          </Switch>
          <button onClick={this.doRegister} className={styles.doLogin}>
            注&nbsp;&nbsp;&nbsp;册
          </button>
          <div className={styles.toRegister}>
            已有账号？
            <span className={styles.toRegisterTitle} onClick={this.toLogin}>
              立即登录
            </span>
          </div>
        </div>
        {this.state.showPop && (
          <div className={styles.popSelect}>
            <div className={styles.popSelectBox}>
              <div className={styles.popTopBox}>
                <div className={styles.popSearchBox}>
                  <input
                    ref={this.valueRefSearch}
                    onChange={this.changeSearch}
                    placeholder='请输入单位名称进行搜索'
                    className={styles.searchIpt}
                    type='text'
                    value={this.searchText}
                    autoFocus
                  />
                  <img
                    src={search}
                    onClick={this.searching}
                    className={styles.popSearchIcon}
                    alt=''
                  />
                </div>
                <div className={styles.popClose} onClick={this.hidePop}>
                  ×
                </div>
              </div>
              <ul className={styles.popList}>
                {this.state.unitList.map((item, index) => (
                  <li
                    key={index}
                    data-unitname={item.unitName}
                    data-unitid={item.unitId}
                    className={styles.popItem}
                    onClick={this.setUnit}
                  >
                    {item.unitName}
                  </li>
                ))}
                {this.state.unitList.length === 0 && (
                  <li className={styles.popItem}>没有搜到该单位</li>
                )}
              </ul>
              {/* <ul className={styles.searchHistory}>
                <li>华东师范大学附属卢湾辅读实验学校</li>
              </ul> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Register;
