import { Component } from 'react';
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
import bg from '../../images/guide-bg.png';
import vr from '../../images/vr.png';
import cxhdkj from '../../images/cxhdkj.png';
import yegbhxxkj from '../../images/yegbhxxkj.png';
import yedgnhds from '../../images/yedgnhds.png';
import yetsyls from '../../images/yetsyls.png';
import yezngjs from '../../images/yezngjs.png';
import yectwhyzty from '../../images/yectwhyzty.png';
import yetnydkj from '../../images/yetnydkj.png';
class TypeThemeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          id: 0,
          title: '创新互动空间',
          img: cxhdkj,
          url: 'https://www.kuleiman.com/122283/index.html'
        },
        {
          id: 1,
          title: '幼儿个别化学习空间 ',
          img: yegbhxxkj,
          url: 'https://www.kuleiman.com/126837/index.html'
        },
        {
          id: 2,
          title: '幼儿多功能活动室',
          img: yedgnhds,
          url: 'https://www.kuleiman.com/126838/index.html'
        },
        {
          id: 3,
          title: '幼儿图书阅览室',
          img: yetsyls,
          url: 'https://www.kuleiman.com/126839/index.html'
        },
        {
          id: 4,
          title: '幼儿智能构造室',
          img: yezngjs,
          url: 'https://www.kuleiman.com/126840/index.html'
        },
        {
          id: 5,
          title: '幼儿传统文化益智体验',
          img: yectwhyzty,
          url: 'https://www.kuleiman.com/126841/index.html'
        },
        {
          id: 6,
          title: '幼儿体适能运动空间',
          img: yetnydkj,
          url: 'https://www.kuleiman.com/126842/index.html'
        }
      ],
      index: 0,
      skipUrl: 'https://www.kuleiman.com/122283/index.html'
    };
  }
  toDetail(id) {
    localStorage.setItem('detailId', id);
    this.props.history.push({
      pathname: '/guideDetail',
      params: id
    });
  }
  componentDidMount() {
    this.props.changeBg(bg, true);
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      observer: true
    });
    new Swiper('.gallery-top', {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      observer: true,
      thumbs: {
        swiper: galleryThumbs
      },
      on: {
        slideChangeTransitionEnd: (that) => {
          this.setState({
            index: that.activeIndex
          });
        }
      }
    });
  }
  toVr = (url) => {
    let aim = '';
    let { list, index } = this.state;
    if (url) {
      aim = url;
    } else {
      aim = list[index].url;
    }
    window.open(aim);
  };
  toVr2 = (url) => {
    setTimeout(() => {
      let aim = '';
      let { list, index } = this.state;
      if (url) {
        aim = url;
      } else {
        aim = list[index].url;
      }
      window.open(aim);
    }, 100);
  };
  render() {
    let { list, index } = this.state;
    return (
      <div className={styles.wrapChild}>
        <div className={`swiper-container gallery-top ${styles.topSwiper}`}>
          <div className={styles.swiperTitle}>{list[index].title}</div>
          <img
            onClick={() => this.toVr(false)}
            className={styles.vr}
            src={vr}
            alt=''
          />
          <div className={`swiper-wrapper ${styles.topSwiperWarp}`}>
            {this.state.list.map((item) => (
              <div
                key={item.id}
                className={`swiper-slide ${styles.topSwiperSlide}`}
              >
                <img className={styles.swiperImg} src={item.img} alt='' />
              </div>
            ))}
          </div>
        </div>
        {/* Add Arrows */}
        <div className='swiper-button-next swiper-button-white'></div>
        <div className='swiper-button-prev swiper-button-white'></div>
        <div
          className={`swiper-container gallery-thumbs ${styles.bottomSwiper}`}
        >
          <div className={`swiper-wrapper ${styles.bottomSwiperWrap}`}>
            {this.state.list.map((item) => (
              <div
                key={item.id}
                className={`swiper-slide ${styles.bottomSwiperSlide}`}
              >
                <img
                  className={`${styles.bottomImg} ${
                    this.state.index !== item.id ? styles.blurCtn : ''
                  }`}
                  src={item.img}
                  alt=''
                />
                <div
                  className={styles.bottomText}
                  onClick={() => this.toVr2(item.url)}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ul className={styles.list}>
          {this.state.list.map((item, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() => this.toVr(item.url)}
            >
              <div
                className={styles.itemBg}
                style={{
                  background: `url(${item.img})center / cover no-repeat`
                }}
              ></div>
              <div className={styles.rightCtn}>
                <p className={styles.itemTitle}>{item.title}</p>
                <article className={styles.itemCtn}>
                  上海教装app聚焦教育一线需求，立足教育教学规律，服务课程建设需要，为本市各级各类学校配送标准化的教学仪器设备、理化生实验设备、体卫艺设备、教学配套材料，服务上海教育均衡化发展
                </article>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
export default withRouter(TypeThemeClass);
