import React, { Component } from 'react'
import { Switch, NavLink, withRouter } from 'react-router-dom'
import styles from './index.module.css'
import logo from '../../images/logo.png'
import ga from '../../images/hz_icon.png'
import avatar from '../../images/default-avatar.png'
import iconIndex from '../../images/icon-index.png'
import iconDiscover from '../../images/icon-discover.png'
import iconGuide from '../../images/icon-guide.png'
import iconSearch from '../../images/icon-search.png'
import btmLogo from '../../images/btm-logo.png'
import gzh from '../../images/gzh-ewm.png'
import xz from '../../images/download.png'
import ScrollTop from '../../components/scrollTop'
import Search from '../../components/search'
import { post } from '../../utils/request'
import store from '../../store/index'
import { BackTop, message } from 'antd'

class PublicContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasScroll: false, //
      avatar: localStorage.getItem('avatar') || avatar,
      searchText: '',
      searchList: [],
      year: new Date().getFullYear(),
      navList: [
        {
          id: 0,
          navName: '首页',
          route: '/',
          icon: iconIndex
        },
        {
          id: 1,
          navName: '发现',
          route: '/discover',
          icon: iconDiscover
        },
        {
          id: 2,
          navName: '综合导航',
          route: '/guide',
          icon: iconGuide
        }
      ], // nav list
      checkIndex: 0 //nav index
    }
    this.handleChange = this.handleChange.bind(this)
    // this.ref = React.createRef();
  }

  // change tab
  changeTab = (id, e) => {
    e.preventDefault()
    this.setState({
      checkIndex: id
    })
  }
  // change tab
  clearList = () => {
    setTimeout(() => {
      this.setState({
        searchList: []
      })
    }, 300)
  }
  handleChange(e) {
    e.preventDefault()
    this.setState({
      searchText: this.input.value
    })
  }
  doSearch = () => {
    this.input.focus()
    if (this.state.searchText) {
      post('/visitor/search', {
        type: 0, //文章列表类型 0、全部 1、装备指南 2、融合案列 3、过期风采 4、教学仪器 5、信息化设备 6、玩教具 7、配套教材
        isCollect: 0, // 是否收藏内容 0、全部 1收藏内容
        searchContent: this.state.searchText,
        page: 1,
        pageSize: 88
      })
        .then(result => {
          if (result.code === 0) {
            this.setState({
              searchList: result.data.pageList
            })
            if (!result.data.pageList.length) {
              message.warning({
                content: '没有搜索到该内容',
                className: 'custom-class',
                style: {
                  marginTop: '20vh'
                }
              })
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
  // 组件加载时
  componentDidMount() {
    store.subscribe(() => {
      this.setState({
        avatar: store.getState().avatar
      })
    })
    window.addEventListener('scroll', this.handleScroll, true)
  }
  // 组件卸载时
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
  // 监听滚动
  handleScroll = () => {
    this.setState({
      hasScroll: document.documentElement.scrollTop
    })
  }
  render() {
    return (
      <div className={styles.layout}>
        <div className={styles.wrap}>
          {/* header */}
          <div className={`${styles.navWrap} ${this.props.location.pathname === '/' && !this.state.hasScroll ? styles.noColor : ''}`}>
            <nav className={styles.nav}>
              <img
                src={logo}
                alt=''
                onClick={() => {
                  this.props.history.push('/')
                }}
                className={styles.logo}
              />
              <ul className={styles.navListBox}>
                {this.state.navList.map(item => (
                  <li key={item.id} className={styles.listBox}>
                    <NavLink exact={item.id === 0} className={styles.listName} to={item.route} activeClassName={styles.selected}>
                      <img className={styles.navIcon} src={item.icon} alt='' />
                      <span className={styles.navName}>{item.navName}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
              <div className={styles.inputBox}>
                <input onBlur={this.clearList} onChange={this.handleChange} className={styles.inputArea} ref={input => (this.input = input)} type='text' name='' id='' maxLength='22' value={this.state.searchText} />
                {this.state.searchList.length > 0 && <Search clearList={this.clearList} searchList={this.state.searchList} />}
                <img onClick={this.doSearch} className={styles.iconSearch} src={iconSearch} alt='' />
              </div>
              <NavLink className={styles.userInfo} to='/mine'>
                <img className={styles.userAvatar} src={this.state.avatar} alt='' />
                <span className={styles.userNickname}>我的</span>
              </NavLink>
            </nav>
          </div>
          {/* content */}
          <ScrollTop>
            <Switch>{this.props.children}</Switch>
          </ScrollTop>
          {/* footer */}
          <footer className={styles.footer}>
            <div className={styles.btmBox}>
              <img className={styles.btmLogo} src={btmLogo} alt='' />
              <div className={styles.middleInfo}>
                <ul className={styles.middleTop}>
                  <li className={styles.tel}>电话：021-63230282</li>
                  <li className={styles.tel}>邮箱：shjyjs@126.com</li>
                  <li className={styles.tel}>传真：021-63237346</li>
                  <li className={styles.tel}>微信公众号：shjyjs</li>
                </ul>
                <p className={styles.componyInfo}>
                  Copyright ©{this.state.year} 上海市教育技术装备服务中心有限公司 ALL RIGHTS RESERVED.
                  <a style={{ color: '#fff' }} href='https://beian.miit.gov.cn' target='_blank' rel='noreferrer'>
                    沪ICP备19027859号-1
                  </a>
                  <br />
                  <img className={styles.ga} src={ga} alt='' />
                  沪公网安备31010102007148号
                  {/* <img className={styles.ga} src={ga} alt='' />
                  沪公网安备31010102007097号 */}
                </p>
              </div>
              <div className={styles.ewmBox}>
                <div className={styles.padding10}>
                  <img style={{ borderRadius: '10px' }} src={gzh} alt='' />
                </div>
                <p>扫码关注公众号</p>
              </div>
              <div className={styles.ewmBox}>
                <div className={styles.padding20}>
                  <img style={{ borderRadius: '10px' }} src={xz} alt='' />
                </div>
                <p>扫码下载app</p>
              </div>
            </div>
          </footer>
          <BackTop style={{ bottom: '184px' }} />
        </div>
      </div>
    )
  }
}

export default withRouter(PublicContent)
