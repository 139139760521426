import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';
class CorrelationLink extends Component {
  toDetail = (code) => {
    localStorage.setItem(
      'detailId',
      JSON.stringify({
        code,
        route: [
          {
            id: 1,
            url: '/',
            pathName: '首页'
          },
          {
            id: 2,
            url: '/',
            pathName: '精选动态'
          },
          {
            id: 3,
            url: '/guideDetail?name=' + code + this.props.type,
            pathName: ''
          }
        ]
      })
    );
    this.props.history.push({
      pathname: '/guideDetail',
      params: code,
      search: 'name=' + code + this.props.type
    });
  };
  componentDidMount() {
  }
  render() {
    return (
      <div className={styles.wrap}>
        <p className={styles.t}>相关链接</p>
        <ul className={styles.listBox}>
          {this.props.articleList.map((item, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() => this.toDetail(item.code)}
            >
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(CorrelationLink);
