import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';

class CollectType extends Component {
  componentDidMount() {}
  render() {
    return (
      <ul className={styles.list}>
        {this.props.rightList.length > 0 &&
          this.props.rightList.map((item, index) => (
            <li
              key={index}
              className={styles.item}
              onClick={() => {
                localStorage.setItem('teachId', item.code);
                localStorage.setItem('productCode', item.productCode);
                localStorage.setItem(
                  'interrelated',
                  JSON.stringify(this.props.rightList.slice(0,6))
                );
                this.props.history.push(
                  this.props.notCollect ? '/teachDetail?type=1' : '/teachDetail'
                );
              }}
            >
              <div
                className={styles.productImg}
                style={{
                  background: `url(${item.bigImgUrl})center /contain no-repeat`
                }}
              ></div>
              {/* <img className={styles.productImg} src={item.bigImgUrl} alt='' /> */}
              <div className={styles.productName}>{item.title}</div>
              <div className={styles.productCode}>
                产品代码：{item.productCode}
              </div>
            </li>
          ))}
        {!this.props.rightList.length && (
          <p className={styles.defaultPage}>此分类暂无产品</p>
        )}
      </ul>
    );
  }
}
export default withRouter(CollectType);
