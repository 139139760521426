import { Component } from 'react';
import { Switch, NavLink, withRouter, Route } from 'react-router-dom';
import styles from './index.module.css';
// import PrivateRoute from '../../components/privateRoute';
import Experimental from '../experimental';
import { post } from '../../utils/request';
class Discover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navList: [], // nav list
      tags: [],
      levelId2: '',
      levelId3: '',
      levelId4: ''
    };
  }
  // get video list
  getVideoTypeList = () => {
    post('/visitor/getVideoTypeList').then((result) => {
      if (result.code === 0) {
        let newArr = result.data[0].subList.map((item, index) => {
          switch (index) {
            case 0:
              item.route = '/';
              break;
            case 1:
              item.route = '/safe';
              break;
            case 2:
              item.route = '/hurt';
              break;
            default:
              item.route = '/';
              break;
          }
          return item;
        });
        this.setState(
          {
            navList: newArr || []
          },
          () => {
            let { navList } = this.state;
            this.setState({
              levelId2: navList[0].videoTypeId,
              levelId3: navList[1].videoTypeId,
              levelId4: navList[2].videoTypeId
            });
          }
        );
      }
    });
  };
  componentDidMount() {
    this.getVideoTypeList();
  }
  render() {
    let { path } = this.props.match;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.secondNav}>
          {this.state.navList.map((item, index) => (
            <NavLink
              key={index}
              exact
              activeClassName={styles.selectTab}
              className={styles.navList}
              to={{
                pathname: `${path}${item.route}`,
                state: { levelId2: this.state.navList[index].videoTypeId }
              }}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
        <Switch>
          <Route exact path={path}>
            <Experimental levelId2={this.state.levelId2} />
          </Route>
          <Route path={`${path}/safe`}>
            <Experimental levelId2={this.state.levelId3} />
          </Route>
          <Route path={`${path}/hurt`}>
            <Experimental levelId2={this.state.levelId4} />
          </Route>
        </Switch>
      </div>
    );
  }
}
export default withRouter(Discover);
