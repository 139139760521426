import { Component } from 'react';

import styles from './index.module.css';
class NotFound extends Component {
  state = {
    time: 5
  };
  componentDidMount() {
    this.time(this.state.time);
  }
  toIndex=()=>{
    window.location.href = window.location.origin;
  }
  // 倒计时
  time = (num) => {
    let timer = setInterval(() => {
      if (num < 0) {
        clearInterval(timer);
        window.location.href = window.location.origin;
        return;
      }
      this.setState({
        time: num--
      });
    }, 1000);
  };
  render() {
    return (
      <div className={styles.wrapChild}>
        <p className={styles.four}>404</p>
        <div style={{ fontSize: 18 }}>
          {this.state.time} 秒后您将去
          <button className={styles.toIndex} onClick={()=>this.toIndex()}>上海教装首页</button>
        </div>
      </div>
    );
  }
}
export default NotFound;
