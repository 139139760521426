import { Component } from 'react';
import { Switch, NavLink, withRouter } from 'react-router-dom';
import styles from './index.module.css';
import Breadcrumb from '../../components/breadcrumb';
import PrivateRoute from '../../components/privateRoute';
import DownloadIndex from '../downloadIndex';
import DownloadInfo from '../downloadInfo';
import DownloadToy from '../downloadToy';
import DownloadMaterial from '../downloadMaterial';
import { post } from '../../utils/request';
class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 下载分类 1、教学仪器 2、信息化设备 3、玩教具 4、配套材料
      type: 1,
      page: 1,
      pageSize: 100,
      list: [],
      pageInfo: {},
      routeList: [
        {
          id: 1,
          url: '/mine',
          pathName: '我的'
        },
        {
          id: 2,
          url: '/download',
          pathName: '目录下载'
        }
      ],
      navList: [
        {
          id: 0,
          navName: '教学仪器',
          route: '/',
          type: 1
        },
        {
          id: 1,
          navName: '信息化设备',
          route: '/info',
          type: 2
        },
        {
          id: 2,
          navName: '玩教具',
          route: '/toy',
          type: 3
        },
        {
          id: 3,
          navName: '配套材料',
          route: '/material',
          type: 4
        }
      ] // nav list
    };
  }
  // get video list
  // 下载分类 1、教学仪器 2、信息化设备 3、玩教具 4、配套材料
  getDownloadFileList = (type) => {
    let { page, pageSize } = this.state;
    post('/visitor/getDownloadFileList', {
      type: type || this.state.type,
      page,
      pageSize
    }).then((result) => {
      if (result.code === 0) {
        result.data.pageList.map((item) => {
          item.status = 1;
          return item;
        });
        this.setState({
          pageInfo: result.data,
          list: result.data.pageList
        });
      }
    });
  };
  changeProps = (i, val) => {
    this.state.list.map((item, index) => {
      if (index === i) {
        item.status = val;
      }
      return item;
    });
    this.setState({
      list: this.state.list
    });
  };
  componentDidMount() {}
  componentDidUpdate(prev) {}
  render() {
    let { path } = this.props.match;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <div className={styles.fixedTop}>
            <div className={styles.bread}>
              <Breadcrumb routeList={this.state.routeList} />
            </div>
            <div className={styles.secondNav}>
              {this.state.navList.map((item) => (
                <NavLink
                  key={item.id}
                  exact
                  activeClassName={styles.selectTab}
                  className={styles.navList}
                  // to={`${path}${item.route}`}
                  to={{
                    pathname: `${path}${item.route}`,
                    state: { type: item.type }
                  }}
                >
                  {item.navName}
                </NavLink>
              ))}
            </div>
          </div>

          <Switch>
            <PrivateRoute exact path={path}>
              <DownloadIndex
                changeProps={this.changeProps}
                pageInfo={this.state.pageInfo}
                getDownloadFileList={this.getDownloadFileList}
                list={this.state.list}
              />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/info`}>
              <DownloadInfo
                changeProps={this.changeProps}
                pageInfo={this.state.pageInfo}
                getDownloadFileList={this.getDownloadFileList}
                list={this.state.list}
              />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/toy`}>
              <DownloadToy
                changeProps={this.changeProps}
                pageInfo={this.state.pageInfo}
                getDownloadFileList={this.getDownloadFileList}
                list={this.state.list}
              />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/material`}>
              <DownloadMaterial
                changeProps={this.changeProps}
                pageInfo={this.state.pageInfo}
                getDownloadFileList={this.getDownloadFileList}
                list={this.state.list}
              />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    );
  }
}
export default withRouter(Download);
