import { Component } from 'react';
import styles from './index.module.css';
import topListBg from '../../images/toplistbg.png';
import TeachDevice from '../teachDevice';
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.changeBg(topListBg);
  }
  render() {
    return (
      <div className={styles.wrapChild}>
        <TeachDevice />
      </div>
    );
  }
}
export default Index;
