import { Component } from 'react'
import { NavLink, Switch, withRouter, Route } from 'react-router-dom'
import styles from './index.module.css'
// import PrivateRoute from '../../components/privateRoute';
import zbzn from '../../images/img-zbzn.png'
import jxsb from '../../images/img-jxsb.png'
import rhal from '../../images/img-rhal.png'
import ztjs from '../../images/img-ztjs.png'
import gqfc from '../../images/img-gqfc.png'
import wyfw from '../../images/img-wyfw.png'
import topListBg from '../../images/toplistbg.png'
import TypeEquipmentGuide from '../typeEquipmentGuide'
import TypeTeachingFacility from '../typeTeachingFacility'
import TypeIntegrationCase from '../typeIntegrationCase'
import TypeThemeClass from '../typeThemeClass'
import TypeElegant from '../typeElegant'
import TypeMaintenance from '../typeMaintenance'
import { post } from '../../utils/request'
import PrivateRoute from '../../components/privateRoute'
class TypeList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      childData: [],
      goodData: [],
      total: 0,
      page: 1,
      pageSize: 20,
      hasNextPage: false,
      bg: topListBg,
      typeList: [
        { id: 1, typeTitle: '装备指南', icon: zbzn, path: '/typeList' },
        { id: 2, typeTitle: '教学设备', icon: jxsb, path: '/typeList/typeTeachingFacility/toy?type=1' },
        { id: 3, typeTitle: '融合案例', icon: rhal, path: '/typeList/typeIntegrationCase' },
        { id: 4, typeTitle: '主题教室', icon: ztjs, path: '/typeList/typeThemeClass' },
        { id: 5, typeTitle: '国企风采', icon: gqfc, path: '/typeList/typeElegant' },
        { id: 6, typeTitle: '运维服务', icon: wyfw, path: '/typeList/typeMaintenance' }
      ]
    }
  }
  // type: 装备指南还是融合案例还是国企风采 more：是不是获取更多
  getList = (type = 1, more = false, year, month) => {
    // 不是更多
    if (!more) {
      this.setState({ childData: [] })
    }
    let { page, pageSize } = this.state
    post('/visitor/getArticleList', { type, page, pageSize, year, month }).then((result) => {
      if (result.code === 0) {
        let oldData = this.state.childData
        this.setState({
          childData: [...oldData, ...result.data.pageList],
          total: result.data.totalSize,
          hasNextPage: result.data.hasNextPage
        })
      }
    })
  }
  getGoodList = () => {
    let { page, pageSize } = this.state
    post('/visitor/getArticleList', {
      type: 8,
      page,
      pageSize
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          goodData: result.data.pageList
        })
      }
    })
  }
  showMore = (type) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.page += 1
    this.setState({
      page: this.state.page
    })
    this.getList(type, true)
  }
  changeBg = (bg, big = false) => {
    this.setState({
      page: 1,
      bg: `url(${bg})center top /100% ${big ? '42%' : 'auto'}  no-repeat`
    })
  }
  componentDidMount() {
    this.getGoodList()
  }
  componentWillUnmount() {
    this.setState = () => false
  }
  render() {
    let { path } = this.props.match
    return (
      <div
        className={styles.wrapChild}
        style={{
          background: this.state.bg
        }}
      >
        <div className={styles.inner}>
          <ul className={styles.topList}>
            {this.state.typeList.map((item) => (
              <li key={item.id} className={styles.topItem}>
                <NavLink exact activeClassName={styles.selectTopType} className={styles.topLink} to={item.path}>
                  <img className={styles.topImg} src={item.icon} alt='' />
                  <p className={styles.typeTitle}>{item.typeTitle}</p>
                </NavLink>
              </li>
            ))}
          </ul>
          <Switch>
            <Route exact path={`${path}`}>
              <TypeEquipmentGuide
                childData={this.state.childData}
                goodData={this.state.goodData}
                page={this.state.goodData.length}
                total={this.state.total}
                hasNextPage={this.state.hasNextPage}
                showMore={this.showMore}
                getList={this.getList}
                changeBg={this.changeBg}
              />
            </Route>
            <Route path={`${path}/typeTeachingFacility`}>
              <TypeTeachingFacility changeBg={this.changeBg} />
            </Route>
            <Route exact path={`${path}/typeIntegrationCase`}>
              <TypeIntegrationCase
                childData={this.state.childData}
                goodData={this.state.goodData}
                page={this.state.goodData.length}
                total={this.state.total}
                hasNextPage={this.state.hasNextPage}
                showMore={this.showMore}
                getList={this.getList}
                changeBg={this.changeBg}
              />
            </Route>
            <Route exact path={`${path}/typeThemeClass`}>
              <TypeThemeClass changeBg={this.changeBg} />
            </Route>
            <Route exact path={`${path}/typeElegant`}>
              <TypeElegant
                childData={this.state.childData}
                goodData={this.state.goodData}
                page={this.state.goodData.length}
                total={this.state.total}
                hasNextPage={this.state.hasNextPage}
                showMore={this.showMore}
                getList={this.getList}
                changeBg={this.changeBg}
              />
            </Route>
            <PrivateRoute exact path={`${path}/typeMaintenance`}>
              <TypeMaintenance changeBg={this.changeBg} />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    )
  }
}
export default withRouter(TypeList)
