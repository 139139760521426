import { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import 'swiper/components/pagination/pagination.min.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.min.css'
import { post } from '../../utils/request'
import topListBg from '../../images/toplistbg.png'
import styles from './index.module.css'
import Pagination from '../../components/pagination'
import { message } from 'antd'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
      selectTags: []
    }
    this.toDetail = this.toDetail.bind(this)
    this.addBg = this.addBg.bind(this)
  }
  hasRead(code) {
    // 是否已读
    let oldList = localStorage.getItem('readCodeList') || ''
    return oldList.indexOf(code) === -1
  }
  toDetail(code) {
    // 是否已读
    let oldList = localStorage.getItem('readCodeList') || ''
    if (oldList.indexOf(code) === -1) {
      localStorage.setItem('readCodeList', oldList + ',' + code)
    }
    localStorage.setItem(
      'detailId',
      JSON.stringify({
        code,
        route: [
          { id: 1, url: '/', pathName: '首页' },
          { id: 2, url: '/typeList/typeIntegrationCase', pathName: '融合案例' },
          { id: 3, url: '/guideDetail?name=' + code + 2, pathName: '' }
        ]
      })
    )
    this.props.history.push({
      pathname: '/guideDetail',
      params: code,
      search: 'name=' + code + 2
    })
  }

  componentDidMount() {
    this.props.changeBg(topListBg)
    setTimeout(() => {
      this.props.getList(2)
    }, 0)
    const years = []
    const months = []
    const yearNow = new Date().getFullYear()
    // 年
    for (let year = 2020; year <= yearNow; year++) years.push({ name: `${year}年`, tagId: year })
    // 月
    for (let month = 1; month < 13; month++) months.push({ name: `${month}月`, tagId: month })
    this.setState({
      tags: [
        { name: '年', tags: years },
        { name: '月', tags: months }
      ]
    })
  }
  componentDidUpdate() {}

  // 删掉tag
  delTag = (e) => {
    let delIndex = e.target.dataset.index * 1
    this.state.selectTags.splice(delIndex, 1)
    this.setState({ selectTags: this.state.selectTags }, () => {
      if (!this.state.selectTags.length) {
        return this.props.getList(2)
      }
      let getId = []
      this.state.selectTags.map((item) => getId.push(item.tagId * 1))
      // 大于12 是年
      let num = getId.filter((item) => item > 12)
      // 删了月
      if (!!num.length) {
        // 只有年
        this.props.getList(2, false, getId[0])
      } else {
        //  删了年
        this.state.selectTags.splice(0, 1)
        this.props.getList(2)
      }
    })
  }
  // 选择tags
  selectTag = (e) => {
    // 选择的tag的坐标
    let index = e.target.dataset.index.split(',')
    // 选择的tag的数据
    let select = JSON.parse(e.target.dataset.tag)
    // 点击的自己
    let clickSelf = this.state.selectTags.filter((item) => {
      return item.tagId === select.tagId
    })
    // 点击的兄弟要把原来的删掉
    let sameFather = this.state.selectTags.filter((item) => {
      return item.fatherIndex === index[0] * 1
    })

    if (!sameFather.length && !clickSelf.length) {
      this.state.selectTags.push({ ...select, fatherIndex: index[0] * 1 })
    } else {
      let newArr = this.state.selectTags.filter((item) => {
        return item.fatherIndex !== index[0] * 1
      })
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectTags = JSON.parse(JSON.stringify(newArr))
      this.state.selectTags.push({ ...select, fatherIndex: index[0] * 1 })
    }
    this.setState(
      {
        selectTags: this.state.selectTags
      },
      () => {
        let getId = []
        this.state.selectTags.map((item) => getId.push(item.tagId * 1))
        let num = getId.filter((item) => item > 12)
        // 选择了年
        if (!!num.length) {
          // 只有年
          if (getId.length === 1) {
            this.props.getList(2, false, getId[0])
          } else {
            const n = getId.sort((a, b) => a - b)
            this.props.getList(2, false, n[1], n[0])
          }
        } else {
          //  只选择了月
          message.warning('请选择年份')
        }
      }
    )
  }
  getProductInfoList = (ids = this.props.levelId2) => {
    post('/visitor/getProductInfoList', {
      levelId3: 1,
      tagIds: ids,
      page: 1,
      pageSize: 20
    }).then((result) => {})
  }
  addBg(name) {
    let arr = this.state.selectTags.filter((item) => item.name === name)
    return !!arr.length
  }
  render() {
    return (
      <div className={styles.wrapChild}>
        {this.state.tags.map((item, fatherI) => (
          <div key={fatherI} className={styles.filterTypes}>
            <div className={styles.dateTypes}>{item.name}</div>
            <ul className={styles.TypesBox}>
              {item.tags.map((child, childI) => (
                <li onClick={this.selectTag} key={childI} data-index={[fatherI, childI]} data-tag={JSON.stringify(child)} className={`${styles.types} ${this.addBg(child.name) && styles.selectType}`}>
                  {child.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
        {!!this.state.tags.length && (
          <div className={styles.filterTypes}>
            <div className={styles.dateTypes}>已选</div>
            <ul className={styles.TypesBox}>
              {this.state.selectTags.map((item, index) => (
                <li key={index} className={`${styles.types} ${styles.selectType} ${styles.selectT}`}>
                  <span>{item.name}</span>
                  <span className={styles.del} onClick={this.delTag} data-index={index}>
                    ×
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <p className={styles.filterContent}>筛选后内容</p>
        <div className={styles.discover}>
          <ul className={styles.discoverList}>
            {this.props.childData.map((item, index) => (
              <li key={index} className={styles.discoverItem} onClick={() => this.toDetail(item.code)}>
                {this.hasRead(item.code) && <div className={styles.readStatus}></div>}
                <div
                  style={{
                    background: `url(${item.bigImgUrl})center / cover no-repeat`
                  }}
                  className={styles.leftImg}
                ></div>
                <div className={styles.wordsBox}>
                  <div className={styles.discoverCtn}>
                    <div className={styles.ctnTitle}>{item.title}</div>
                    <div className={styles.ctnTime}>{`${item.addTime.slice(5, 7)}.${item.addTime.slice(8, 10)}`}</div>
                  </div>
                  <p className={styles.ctn}>{item.desc}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.rightSwiper}>
            <Swiper
              autoPlay={true}
              pagination={{ clickable: true }}
              className={styles.swiperWrap}
              onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {this.props.goodData.map((item, index) => (
                <SwiperSlide key={index} className={styles.swiperItem} onClick={() => this.toDetail(item.code)}>
                  {/* <img
                    className={styles.swiperImg}
                    src={item.bigImgUrl}
                    alt=''
                  /> */}
                  <div
                    style={{
                      background: `url(${item.bigImgUrl})center / cover no-repeat`
                    }}
                    className={styles.swiperImg}
                  ></div>
                  <article className={styles.articleCtn}>
                    <h3 className={styles.artTitle}>{item.title}</h3>
                    <div className={styles.artCtn}>{item.desc}</div>
                  </article>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Pagination list={this.props.childData} totalNum={this.props.total} hasNextPage={this.props.hasNextPage} showMore={() => this.props.showMore(2)}></Pagination>
      </div>
    )
  }
}
export default withRouter(Index)
