import { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PrivateRoute from '../../components/privateRoute';
import PublicContent from '../../components/publicContent';
import TabIndex from '../index';
import TabDiscover from '../discover';
import TabGuide from '../guide';
import Login from '../login';
import BindPhone from '../bindPhone';
import Register from '../register';
import NotFound from '../pageNotFound'
import TypeList from '../typeList';
import GuideDetail from '../guideDetail';
import TeachDetail from '../teachDetail';
import Collect from '../collect';
import Download from '../download';
import Mine from '../mine';
import EditInfo from '../editInfo';
class Layout extends Component {
  componentDidMount() {
    // console.log('路由列表', routerList);
  }
  render() {
    return (
      <PublicContent>
        <Route exact path='/'>
          <TabIndex />
        </Route>
        <Route path='/discover'>
          <TabDiscover />
        </Route>
        <Route path='/guide'>
          <TabGuide />
        </Route>
        <PrivateRoute path='/mine'>
          <Mine />
        </PrivateRoute>
        <PrivateRoute path='/collect'>
          <Collect />
        </PrivateRoute>
        <PrivateRoute path='/download'>
          <Download />
        </PrivateRoute>
        <PrivateRoute path='/editInfo'>
          <EditInfo />
        </PrivateRoute>
        <Route path='/typeList' component={TypeList} />
        <Route path='/guideDetail' component={GuideDetail} />
        <Route path='/teachDetail' component={TeachDetail} />
        <Route path='/bindPhone' component={BindPhone} />
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='*' component={NotFound} />
      </PublicContent>
    );
  }
}

export default withRouter(Layout);
