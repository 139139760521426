import { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styles from './index.module.css';
import zbzn from '../../images/i-zbzn.png';
import jxsb from '../../images/i-jyzb.png';
import rhal from '../../images/i-rhal.png';
import ztjs from '../../images/i-ztjs.png';
import gqfc from '../../images/i-gqfc.png';
import wyfw from '../../images/i-wyfw.png';
class CommonFun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [
        {
          id: 1,
          path: '/typeList',
          name: '装备指南',
          icon: zbzn
        },
        {
          id: 2,
          path: '/typeList/typeTeachingFacility',
          name: '教育装备',
          icon: jxsb
        },
        {
          id: 3,
          path: '/typeList/typeIntegrationCase',
          name: '融合案例',
          icon: rhal
        },
        {
          id: 4,
          path: '/typeList/typeThemeClass',
          name: '主题教室',
          icon: ztjs
        },
        {
          id: 5,
          path: '/typeList/typeElegant',
          name: '国企风采',
          icon: gqfc
        },
        {
          id: 6,
          path: '/typeList/typeMaintenance',
          name: '运维服务',
          icon: wyfw
        }
      ]
    };
  }

  render() {
    return (
      <div className={styles.wrap}>
        <p className={styles.t}>常用功能</p>
        <ul className={styles.listBox}>
          {this.state.routes.map((item) => (
            <li key={item.id} className={styles.item}>
              <Link to={item.path}>
                <img className={styles.funIcon} src={item.icon} alt='' />
                <p className={styles.funName}>{item.name}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(CommonFun);
