import { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Pagination from '../../components/pagination';
import styles from './index.module.css';
class DownloadIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      hasNextPage: false,
      page: 1,
      pageSize: 100,
      routeList: [
        {
          id: 1,
          url: '/mine',
          pathName: '我的'
        },
        {
          id: 2,
          url: '/mine/collect',
          pathName: '目录下载'
        }
      ],
      navList: [
        {
          id: 0,
          navName: '教学仪器',
          route: 0
        },
        {
          id: 1,
          navName: '信息化设备',
          route: 1
        },
        {
          id: 2,
          navName: '玩教具',
          route: 2
        },
        {
          id: 3,
          navName: '配套材料',
          route: 1
        }
      ] // nav list
    };
  }
  showMore = (type) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.page += 1;
    this.setState({
      page: this.state.page
    });
    this.getList(type, true);
  };
  download = (url, i) => {
    setTimeout(() => {
      this.props.changeProps(i, 2);
    }, 1);
    setTimeout(() => {
      this.props.changeProps(i, 0);
    }, 2000);
    window.open(url);
  };
  componentDidMount() {
    this.props.getDownloadFileList(
      this.props.location.state ? this.props.location.state.type : 1
    );
  }
  render() {
    // let { path } = this.props.match;
    return (
      <div className={styles.wrap}>
        <div className={styles.inner}>
          <ul className={styles.title}>
            <li className={styles.t}>文件名</li>
            <li className={styles.t}>状态</li>
            {/* <li className={styles.t}>大小</li> */}
          </ul>
          <ul className={styles.list}>
            {this.props.list.map((item, index) => (
              <li key={index} className={styles.item}>
                <p className={styles.itemLeft}>{item.title}</p>
                <div className={styles.itemMiddle}>
                  <div
                    style={{ color: 'rgb(42 94 167)' }}
                    className={styles.waitDownload}
                    onClick={() => this.download(item.url, index)}
                  >
                    打开文件
                  </div>
                  {/* {item.status === 0 ? (
                    <div className={styles.downloadOk}>下载完成</div>
                  ) : item.status === 1 ? (
                    // <a href='https://dldir1.qq.com/weixin/Windows/WeChatSetup.exe'>
                    <div
                      className={styles.waitDownload}
                      onClick={() => this.download(item.url, index)}
                    >
                      待下载
                    </div>
                  ) : (
                    <div className={styles.downloading}>99%</div>
                  )} */}
                </div>
                {/* <div className={styles.itemRight}>
                  {item.fileSize !== -1 ? item.fileSize : '未知大小'}
                </div> */}
              </li>
            ))}
          </ul>
        </div>
        {/* <p className={styles.getMore}>加载更多内容</p>
        <p className={styles.listCount}>
          {this.props.pageInfo.endPageNum}/{this.props.pageInfo.totalSize}个结果
        </p> */}
      </div>
    );
  }
}
export default withRouter(DownloadIndex);
