import { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styles from './index.module.css';
import arrow from '../../images/arrow.png';
import { post } from '../../utils/request';
class Guide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        {
          title: '常用模块',
          secondNav: [
            {
              title: '装备指南',
              route: '/typeList'
            },
            {
              title: '教学设备',
              route: '/typeList/typeTeachingFacility/toy?type=1'
            },
            {
              title: '融合案例',
              route: '/typeList/typeIntegrationCase'
            },
            {
              title: '主题教室',
              route: '/typeList/typeThemeClass'
            },
            {
              title: '国企风采',
              route: '/typeList/typeElegant'
            },
            {
              title: '运维服务',
              route: '/typeList/typeMaintenance'
            }
          ]
        },
        {
          title: '产品类别',
          secondNav: [
            {
              title: '教学仪器',
              route: '/collect/teach?type=1'
            },
            {
              title: '信息化设备',
              route: '/collect/info?type=1'
            },
            {
              title: '玩教具',
              route: '/collect/toy?type=1'
            },
            {
              title: '配套材料',
              route: '/collect/material?type=1'
            }
          ]
        },
        {
          title: '核心功能',
          secondNav: [
            {
              title: '目录下载',
              route: '/download'
            },
            {
              title: '我的收藏',
              route: '/collect/teach'
            },
            {
              title: '发现',
              route: '/discover'
            }
          ]
        },
        {
          title: '其他',
          secondNav: [
            {
              title: '个人信息',
              route: '/mine'
            },
            {
              title: '设置',
              route: '/editInfo'
            }
          ]
        }
      ]
    };
  }
  getNavigationHotList = () => {
    post('/visitor/getNavigationHotList').then((result) => {
      // this.state.nav.push({
      //   title: '推荐',
      //   secondNav: [...result.data]
      // });
      // this.setState({
      //   nav: this.state.nav
      // });
      console.log(result);
    });
  };
  componentDidMount() {
    this.getNavigationHotList();
  }
  toDetail = (route) => {
    console.log('====================================');
    console.log(route);
    console.log('====================================');
    if (route.indexOf('typeList') !== -1) {
      let hasLogin = localStorage.getItem('hasLogin');
      // 没登陆不让看了
      if (hasLogin === 'false') {
        window.location.href = '/login';
        return false;
      }
    }
    this.props.history.push(route);
  };
  render() {
    return (
      <div className={styles.wrapChild}>
        <div className={styles.typeBox}>
          {this.state.nav.map((items, index) => (
            <Fragment key={index}>
              <p className={styles.typeTitle}>{items.title}</p>
              <ul className={styles.typeList}>
                {items.secondNav.map((item, index) => (
                  <li key={index} className={styles.type}>
                    <div
                      className={styles.links}
                      to={item.route}
                      onClick={() => this.toDetail(item.route)}
                    >
                      <span>{item.title}</span> <img src={arrow} alt='' />
                    </div>
                  </li>
                ))}
              </ul>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
}
export default withRouter(Guide);
