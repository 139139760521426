import { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styles from '../collect/child.module.css';
import CollectType from '../../components/collectType';
import Pagination from '../../components/pagination';
import { post } from '../../utils/request';
class CollectMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftList: [],
      rightList: [],
      leftIndex: 0,
      total: 0,
      hasNextPage: false,
      page: 1,
      pageSize: 100
    };
  }
  getProductList = () => {
    post('/visitor/getProductList', {
      levelType: 7
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          leftList: result.data.length ? result.data[0].subList : []
        });
        if (result.data.length) {
          localStorage.setItem(
            'levelId3',
            result.data[0].subList[0].levelId3 + ',' + 0
          );
        }
        let notCollect = this.props.location.search.indexOf('type') !== -1;
        if (notCollect) {
          this.getProductInfoList(
            result.data.length ? result.data[0].subList[0].levelId3 : 1
          );
        } else {
          this.getProductCollectList(
            result.data.length ? result.data[0].subList[0].levelId3 : 1
          );
        }
      }
    });
  };
  dependType = (levelId3, index) => {
    localStorage.setItem('levelId3', levelId3 + ',' + index);
    let notCollect = this.props.location.search.indexOf('type') !== -1;
    if (notCollect) {
      this.getProductInfoList(levelId3, index);
    } else {
      this.getProductCollectList(levelId3, index);
    }
  };
  getProductInfoList = (levelId3, leftIndex) => {
    post('/visitor/getProductInfoList', {
      levelId3: levelId3,
      page: this.state.page,
      pageSize: this.state.pageSize
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          leftIndex: typeof leftIndex === 'number' ? leftIndex : 0,
          rightList: this.state.rightList.concat(result.data.pageList),
          hasNextPage: result.data.hasNextPage,
          total: result.data.totalSize
        });
      }
    });
  };
  getProductCollectList = (levelId3, leftIndex) => {
    post('/product/getProductCollectList', {
      levelId3: levelId3,
      page: this.state.page,
      pageSize: this.state.pageSize
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          leftIndex: typeof leftIndex === 'number' ? leftIndex : 0,
          rightList: result.data.pageList,
          hasNextPage: result.data.hasNextPage,
          total: result.data.totalSize
        });
      }
    });
  };
  showMore = (type) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.page += 1;
    this.setState({
      page: this.state.page
    });
    let levelId3 = localStorage.getItem('levelId3').split(',');
    this.getProductInfoList(levelId3[0], levelId3[1], true);
  };
  componentDidMount() {
    this.getProductList();
  }
  componentWillUnmount() {
    this.setState = () => false;
  }
  render() {
    return (
      <div className={styles.wrap}>
        {this.state.leftList.length > 0 && (
          <div className={styles.inner}>
            <ul className={styles.leftPart}>
              {this.state.leftList.map((item, index) => (
                <li
                  key={index}
                  className={`${styles.partItem} ${
                    this.state.leftIndex === index ? styles.select : ''
                  }`}
                  onClick={() => this.dependType(item.levelId3, index)}
                >
                  {item.levelName3}
                </li>
              ))}
            </ul>
            <CollectType
              notCollect={this.props.location.search.indexOf('type') !== -1}
              rightList={this.state.rightList}
            />
          </div>
        )}
        {this.state.leftList.length !== 0 && (
          <Pagination
            list={this.state.rightList}
            totalNum={this.state.total}
            hasNextPage={this.state.hasNextPage}
            showMore={() => this.showMore()}
          ></Pagination>
        )}
        {this.state.leftList.length === 0 && (
          <Fragment>
            <p className={styles.getMore}>此分类暂无产品</p>
          </Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(CollectMaterial);
