import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styles from './index.module.css';
import { post } from '../../utils/request';
// import { message } from 'antd';
import avatar from '../../images/default-avatar.png';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
class Mine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideInner: false,
      userName: '',
      userId: '',
      avatarUrl: avatar
    };
    this.exit = this.exit.bind(this);
    this.hideFather = this.hideFather.bind(this);
    this.toEdit = this.toEdit.bind(this);
  }
  exit() {
    post('/user/logout').then((result) => {
      if (result.code === 0) {
        localStorage.clear();
        document.cookie = 'userToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = 'thirdTokenKey=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        this.props.dispatch({
          type: 'AVATAR',
          value: avatar
        });
        this.props.history.push('/');
      }
    });
  }
  hideFather() {
    this.setState({
      hideInner: !this.state.hideInner
    });
  }
  toEdit() {
    this.props.history.push('/editInfo');
  }
  getUserInfo() {
    // 如果是手动输入url  调用api 查看 是否登录
    post('/user/getUserInfo').then((result) => {
      if (result.code === 0) {
        let { avatarUrl, userName, userId } = result.data || {};
        this.setState({
          userName,
          userId,
          avatarUrl
        });
      }
    });
  }
  componentDidMount() {
    setTimeout(() => {
      this.getUserInfo();
    }, 100);
  }
  componentWillUnmount() {}
  render() {
    // let { path } = this.props.match;
    return (
      <div className={styles.wrapChild}>
        {!this.state.hideInner && (
          <div className={styles.inner}>
            <div className={styles.exit} onClick={this.exit}>
              退出账号
            </div>
            <div className={styles.topInfo}>
              <img
                className={styles.avatar}
                src={this.state.avatarUrl}
                alt='用户头像'
              />
              <p className={styles.nickname}>{this.state.userName}</p>
              <p className={styles.userId}>ID ：{this.state.userId}</p>
              <div onClick={this.toEdit} to={`/editInfo`}>
                <p className={styles.editInfo}>编辑个人信息</p>
              </div>
            </div>
            <div className={styles.myThing}>
              <Link to={`/collect/toy`}>
                <div className={`${styles.thingItem} ${styles.myCollect}`}>
                  我的收藏
                </div>
              </Link>
              <Link to={`/download`}>
                <div className={`${styles.thingItem} ${styles.myDownload}`}>
                  目录下载
                </div>
              </Link>
            </div>
          </div>
        )}
        {/* <Switch>
          <PrivateRoute path={`/collect`}>
            <Collect hideFather={this.hideFather} />
          </PrivateRoute>
          <PrivateRoute path={`/download`}>
            <Download hideFather={this.hideFather} />
          </PrivateRoute>
          <PrivateRoute exact path={`/editInfo`}>
            editInfo
          </PrivateRoute>
        </Switch> */}
      </div>
    );
  }
}
export default connect()(withRouter(Mine));
