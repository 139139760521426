import { Component } from 'react';
import styles from './index.module.css';
import { post } from '../../utils/request';
import SwiperCore, { Pagination } from 'swiper';
import { withRouter } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import { connect } from 'react-redux';
import topImg from '../../images/index-top-bg.gif';
import zbzn from '../../images/img-zbzn.png';
import jxsb from '../../images/img-jxsb.png';
import rhal from '../../images/img-rhal.png';
import ztjs from '../../images/img-ztjs.png';
import gqfc from '../../images/img-gqfc.png';
import wyfw from '../../images/img-wyfw.png';
import like from '../../images/like.png';
import unlike from '../../images/unlike.png';

// install Swiper modules
SwiperCore.use([Pagination]);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoTypeId: '',
      discoverList: [], // discover list
      discoverTypeList: [], // discover type list
      articleList: [], // article list
      caseList: [
        {
          id: 1,
          caseTitle: '装备指南',
          caseDesc: '聚焦教育一线，聚合优质资源',
          icon: zbzn
        },
        {
          id: 2,
          caseTitle: '教学设备',
          caseDesc: '专业遴选，提供安全优质教育装备',
          icon: jxsb
        },
        {
          id: 3,
          caseTitle: '融合案例',
          caseDesc: '科学建配方案库和优质技术服务',
          icon: rhal
        },
        {
          id: 4,
          caseTitle: '主题教室',
          caseDesc: 'VR全景教室方案展示',
          icon: ztjs
        },
        {
          id: 5,
          caseTitle: '国企风采',
          caseDesc: '为国企高质量发展提供活力源泉',
          icon: gqfc
        },
        {
          id: 6,
          caseTitle: '运维服务',
          caseDesc: '服务教育教学需求，提升设备使用绩效',
          icon: wyfw
        }
      ]
    };
  }
  componentDidMount() {
    //  article list
    this.getArticleList();
    // video list from type
    this.getVideoTypeList();
    // let token = document.cookie.split('userToken=')[1];
    // if (token) {
    this.getUserInfo();
    // }
  }
  getUserInfo() {
    // 如果是手动输入url  调用api 查看 是否登录
    post('/user/getUserInfo').then((result) => {
      if (result.code === 0) {
        this.props.dispatch({
          type: 'AVATAR',
          value: result.data.avatarUrl
        });
        let nowTimestamp = new Date().getTime() + 86400000 * 7;
        if (document.cookie.split('userToken=')[1]) {
          localStorage.setItem(
            'token',
            `${document.cookie.split('userToken=')[1]},${nowTimestamp}`
          );
        }
        localStorage.setItem(
          'info',
          result.data.userName + ',' + result.data.phone
        );
        let code = result.code !== 1 && result.code !== 4 && result.code !== 5;
        localStorage.setItem('hasLogin', code);
        localStorage.setItem('avatar', result.data.avatarUrl);
      }
      if (result.code === 1) {
        localStorage.setItem('hasLogin', false);
      }
    });
  }
  componentWillUnmount() {
    this.setState = () => false;
  }
  toTypeList = (id) => {
    let hasLogin = localStorage.getItem('hasLogin');
    // 没登陆不让看了
    if (hasLogin === 'false') {
      window.location.href = '/login';
      return false;
    }
    let path = '';
    switch (id) {
      case 0:
        path = '';
        break;
      case 1:
        // path = '/typeTeachingFacility';
        path = '/typeTeachingFacility/toy?type=1';
        break;
      case 2:
        path = '/typeIntegrationCase';
        break;
      case 3:
        path = '/typeThemeClass';
        break;
      case 4:
        path = '/typeElegant';
        break;
      case 5:
        path = '/typeMaintenance';
        break;
      default:
        break;
    }
    this.props.history.push(`/typeList${path}`);
  };
  // get article list (精选动态)
  // 文章列表类型 1、装备指南 2、融合案列 3、国企风采 8、精选内容
  getArticleList = () => {
    post('/visitor/getArticleList', {
      type: 8, // 文章列表类型
      page: 1,
      pageSize: 3
    }).then((result) => {
      if (result.code === 0) {
        this.setState({
          articleList: result.data.pageList.slice(0, 3)
        });
      }
    });
  };
  // get video list
  getVideoTypeList = () => {
    post('/visitor/getVideoTypeList').then((result) => {
      if (result.code === 0) {
        this.setState(
          {
            discoverTypeList: result.data
          },
          () => {
            this.getVideoList();
          }
        );
      }
    });
  };
  // get video list
  getVideoList = () => {
    let typeIndex = 0;
    let { subList } = this.state.discoverTypeList[0];
    post('/visitor/getVideoList', {
      page: 1,
      pageSize: 8,
      videoTypeId: subList[typeIndex].videoTypeId
    }).then((result) => {
      if (result.code === 0) {
        if (result.data.pageList.length) {
          this.setState({
            discoverList: result.data.pageList
          });
        } else {
          typeIndex++;
          this.getVideoList(subList[typeIndex].videoTypeId);
        }
      }
    });
  };

  // do up / cancel  up
  videoUp = (code, type) => {
    post('/video/videoUp', { code, type: type * 1 ? 0 : 1 }).then((result) => {
      if (result.code === 0) {
        let newList = this.state.discoverList.map((item) => {
          if (item.code === code) {
            item.isUp = !type;
            item.likeNum = result.data.likeNum;
          }
          return item;
        });
        this.setState({
          discoverList: [...newList]
        });
      } else {
        this.props.history.push('/login');
      }
    });
  };
  // 更多
  showMoreArticle = () => {
    let hasLogin = localStorage.getItem('hasLogin');
    // 没登陆不让看了
    if (hasLogin === 'false') {
      window.location.href = '/login';
      return false;
    }
    this.props.history.push('/typeList');
  };
  // 更多
  showMore = () => {
    let hasLogin = localStorage.getItem('hasLogin');
    // 没登陆不让看了
    if (hasLogin === 'false') {
      window.location.href = '/login';
      return false;
    }
    this.props.history.push('/discover');
  };
  toDetail(code) {
    let hasLogin = localStorage.getItem('hasLogin');
    // 没登陆不让看了
    if (hasLogin === 'false') {
      window.location.href = '/login';
      return false;
    }
    localStorage.setItem(
      'detailId',
      JSON.stringify({
        code,
        route: [
          {
            id: 1,
            url: '/',
            pathName: '首页'
          },
          {
            id: 2,
            url: '/',
            pathName: '精选动态'
          },
          {
            id: 3,
            url: '/guideDetail?name=' + code + 8,
            pathName: ''
          }
        ]
      })
    );
    this.props.history.push({
      pathname: '/guideDetail',
      params: code,
      search: 'name=' + code + 8
    });
  }
  render() {
    return (
      <div className={styles.wrapChild}>
        <img className={styles.indexTopBg} src={topImg} alt='' />
        <div className={styles.caseBox}>
          <ul className={styles.caseList}>
            {this.state.caseList.map((item, index) => (
              <li
                key={item.id}
                className={styles.case}
                onClick={() => {
                  this.toTypeList(index);
                }}
              >
                <img className={styles.caseIcon} src={item.icon} alt='icon' />
                <p className={styles.caseTitle}>{item.caseTitle}</p>
                <p className={styles.caseDesc}>{item.caseDesc}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.concentration}>
          <div className={styles.concentrationCtn}>
            <div className={styles.leftSwiper}>
              <div className={styles.tag}>精选动态</div>
              <div className={styles.swiper}>
                <Swiper
                  autoPlay={true}
                  pagination={{ clickable: true }}
                  className={styles.swiperWrap}
                  onSlideChange={() => console.log('slide change')}
                  // onSwiper={(swiper) => console.log(swiper)}
                >
                  {this.state.articleList.map((item, index) => (
                    <SwiperSlide
                      onClick={() => this.toDetail(item.code)}
                      key={index}
                      className={styles.swiperItem}
                    >
                      <div
                        className={styles.swiperImg}
                        style={{
                          background: `url(${item.bigImgUrl})top center /cover no-repeat`
                        }}
                      ></div>

                      <article className={styles.articleCtn}>
                        <h3 className={styles.artTitle}>{item.title}</h3>
                        <div className={styles.artCtn}>{item.desc}</div>
                      </article>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className={styles.rightList}>
              <ul className={styles.activeList}>
                {this.state.articleList.map((item, index) => (
                  <li
                    key={index}
                    className={styles.actives}
                    onClick={() => this.toDetail(item.code)}
                  >
                    <div className={styles.activeTop}>
                      {/* <div className={styles.activesType}>【国企风采】</div> */}
                      <div className={styles.activesName}>{item.title}</div>
                      <div className={styles.activesTime}>
                        {item.addTime.slice(5, 7) +
                          '.' +
                          item.addTime.slice(8, 10)}
                      </div>
                    </div>
                    <p className={styles.activesContent}>{item.desc}</p>
                  </li>
                ))}
                <li className={styles.more} onClick={this.showMoreArticle}>
                  更多 &gt;
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.discoverList.length > 0 && (
          <div className={styles.discover}>
            <div className={styles.discoverWrap}>
              <div className={styles.discoverTag}>发现</div>
              <div className={styles.discoverMore}>
                <span style={{ cursor: 'pointer' }} onClick={this.showMore}>
                  更多 &gt;
                </span>
              </div>
              <ul className={styles.discoverList}>
                {this.state.discoverList.map((item) => (
                  <li key={item.code} className={styles.discoverItem}>
                    <img
                      className={styles.discoverImg}
                      src={item.imgUrl}
                      alt=''
                      onClick={() => {
                        let hasLogin = localStorage.getItem('hasLogin');
                        // 没登陆不让看了
                        if (hasLogin === 'false') {
                          window.location.href = '/login';
                          return false;
                        }
                        window.open(item.url);
                      }}
                    />
                    <p className={styles.discoverTitle}>{item.title}</p>
                    <div className={styles.discoverLike}>
                      <img
                        className={styles.iconLike}
                        src={item.isUp ? like : unlike}
                        alt=''
                        onClick={() => this.videoUp(item.code, item.isUp)}
                      />
                      {item.likeNum || 0}喜欢
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect()(withRouter(Index));
