import { Component } from 'react';
import { Switch, NavLink, withRouter } from 'react-router-dom';
import styles from './index.module.css';
import Breadcrumb from '../../components/breadcrumb';
import PrivateRoute from '../../components/privateRoute';
import CollectIndex from '../collectIndex';
import CollectInfo from '../collectInfo';
import CollectToy from '../collectToy';
import CollectMaterial from '../collectMaterial';
class Collect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeList: [
        {
          id: 1,
          url: '/mine',
          pathName: '我的'
        },
        {
          id: 2,
          url: '/collect',
          pathName: '我的收藏'
        }
      ],
      navList: [
        {
          id: 0,
          navName: '教学仪器',
          route: '/collect/teach'
        },
        {
          id: 1,
          navName: '信息化设备',
          route: '/collect/info'
        },
        {
          id: 2,
          navName: '玩教具',
          route: '/collect/toy'
        },
        {
          id: 3,
          navName: '配套材料',
          route: '/collect/material'
        }
      ] // nav list
    };
  }
  componentDidMount() {
    let notCollect = this.props.location.search.indexOf('type') !== -1;
    if (notCollect) {
      this.state.navList.map((item) => {
        item.route += '?type=1';
        return item;
      });
      let str = JSON.stringify(this.state.navList);
      this.setState({
        navList: JSON.parse(str)
      });
    }
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <div className={styles.fixedTop}>
            {this.props.location.search.indexOf('type') === -1 && (
              <div className={styles.bread}>
                <Breadcrumb routeList={this.state.routeList} />
              </div>
            )}
            <div className={styles.secondNav}>
              {this.state.navList.map((item) => (
                <NavLink
                  key={item.id}
                  exact
                  activeClassName={styles.selectTab}
                  className={styles.navList}
                  to={item.route}
                >
                  {item.navName}
                </NavLink>
              ))}
            </div>
          </div>
          <div className={styles.box}>
            <Switch>
              <PrivateRoute path={'/collect/teach'}>
                <CollectIndex />
              </PrivateRoute>
              <PrivateRoute path={'/collect/info'}>
                <CollectInfo />
              </PrivateRoute>
              <PrivateRoute path={'/collect/toy/'}>
                <CollectToy />
              </PrivateRoute>
              <PrivateRoute path={'/collect/material'}>
                <CollectMaterial />
              </PrivateRoute>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Collect);
