import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { post } from '../../utils/request';
import { verifyPhone } from '../../utils/utils';
import styles from './index.module.css';
import wechat from '../../images/big-wechat.png';
import qq from '../../images/big-qq.png';
import alipay from '../../images/big-alipay.png';
import logo from '../../images/big-logo.png';
import link from '../../images/icon-link.png';
import tel from '../../images/icon-tel.png';
import yzm from '../../images/icon-yzm.png';
import { message } from 'antd';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
class BindPhone extends Component {
  state = {
    type: '',
    code: '',
    mobile: '', //your tel
    smsCode: '', //
    warnPhone: false, // show  red color waring
    warnCode: false, // show  red color waring
    countdown: 60,
    buttonType: 0 // 验证码后面的按钮  0获取   1倒计时 2重新获取
  };
  // 手机号失焦
  handleBlur = (e) => {
    if (!this.state.mobile) {
      this.setState({
        warnPhone: true
      });
    }
  };
  // 验证码失焦
  blurCode = (e) => {
    if (!this.state.smsCode) {
      this.setState({
        warnCode: true
      });
    }
  };
  // 验证码失焦
  focusCode = (e) => {
    if (!this.state.smsCode) {
      this.setState({
        warnCode: false
      });
    }
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState({
      mobile: val,
      warnPhone: !verifyPhone(val)
    });
  };

  handleCode = (e) => {
    let val = e.target.value;
    this.setState({
      smsCode: val
    });
  };
  // 倒计时
  countdown = (callback) => {
    clearInterval(this.timer);
    let { countdown } = this.state;
    this.timer = setInterval(() => {
      if (countdown === 0) {
        this.setState({
          countdown: 60,
          buttonType: 2
        });
        clearInterval(this.timer);
      } else {
        this.setState({
          countdown: countdown--
        });
      }
    }, 1000);
  };
  // 获取验证码
  getCode = () => {
    this.setState({
      warnPhone: !verifyPhone(this.state.mobile)
    });
    if (verifyPhone(this.state.mobile)) {
      this.setState({ buttonType: 1 });
      // 开始倒计时
      this.countdown();
      console.log('获取验证码成功');
      this.doGetCode();
    }
  };
  doGetCode() {
    post('/visitor/sendSmsCode', {
      phone: this.state.mobile,
      type: 3
    }).then((result) => {
      if (result.code === 0) {
        message.success({
          content: '验证码发送成功',
          className: 'custom-class',
          style: {
            marginTop: '20vh'
          }
        });
      }
    });
  }
  // doBind
  doBind = () => {
    let { mobile, smsCode } = this.state;
    if (mobile && smsCode) {
      post('/visitor/thirdLoginBind', {
        mobile,
        code: smsCode,
        thirdTokenKey: this.state.code
      }).then((result) => {
        if (result.code === 0) {
          if (result.data.loginType * 1 === 1) {
            let nowTimestamp = new Date().getTime() + 86400000 * 7;
            localStorage.setItem(
              'token',
              `${
                result.data.userToken || document.cookie.split('userToken=')[1]
              },${nowTimestamp}`
            );
            localStorage.setItem(
              'info',
              result.data.userName + ',' + result.data.phone
            );
            this.props.dispatch({
              type: 'AVATAR',
              value: result.data.avatarUrl
            });
            message.success({
              content: '登录成功',
              className: 'custom-class',
              style: {
                marginTop: '20vh'
              }
            });
            window.location.href = 'https://www.shsjyjszbfwzx.cn';
          }
          if (result.data.loginType * 1 === 2) {
            window.location.href = 'https://www.shsjyjszbfwzx.cn/register';
          }
        }
      });
    } else {
      this.setState({ warnPhone: !verifyPhone(mobile) });
      this.setState({ warnCode: !smsCode });
    }
  };

  // 去注册
  toRegister = () => {
    this.props.history.push('register');
  };
  // 第三方登录
  loginThird = (third) => {
    console.log(`${third}登录`);
  };
  componentDidMount() {
    if (localStorage.getItem('hasLogin') === 'true') {
      this.props.history.push('/');
      return;
    }
    let type =
      this.props.location.search.slice(6) ||
      localStorage.getItem('type').split(',')[0];
    let code = document.cookie.split('thirdTokenKey=')[1];
    this.setState({
      type,
      code
    });
  }
  render() {
    let { type } = this.state;
    let flag = localStorage.getItem('hasLogin') === 'true';
    return flag ? (
      <Redirect to='/'></Redirect>
    ) : (
      <div className={styles.wrapChild}>
        <div className={styles.inputBox}>
          <div className={styles.thirdIcon}>
            <img className={styles.logo} src={logo} alt='' />
            <img className={styles.link} src={link} alt='' />
            {type === 'wechat' && (
              <img className={styles.wechat} src={wechat} alt='' />
            )}
            {type === 'QQ' && <img className={styles.qq} src={qq} alt='' />}
            {type === 'alipay' && (
              <img className={styles.alipay} src={alipay} alt='' />
            )}
          </div>
          <p className={styles.inputTitle}>
            登录上海教育装备，并与
            {type === 'wechat' ? '微信' : type === 'QQ' ? 'QQ' : '支付宝'}绑定
          </p>
          <div className={styles.inputBottom}>
            <div className={styles.inputArea}>
              <img className={styles.iptIcon} src={tel} alt='' />
              <input
                className={styles.telIpt}
                maxLength='11'
                placeholder='请输入手机号'
                type='tel'
                value={this.state.mobile}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              {this.state.warnPhone ? (
                <span className={styles.warning}>请输入正确的手机号</span>
              ) : (
                ''
              )}
            </div>
            <div className={styles.inputArea}>
              <img className={styles.iptIcon} src={yzm} alt='' />
              <input
                maxLength='6'
                className={`${styles.yzmIpt}`}
                placeholder='请输入验证码'
                type='tel'
                value={this.state.smsCode}
                onChange={this.handleCode}
                onBlur={this.blurCode}
                onFocus={this.focusCode}
              />
              {this.state.warnCode ? (
                <p className={styles.warning}>请输入验证码</p>
              ) : (
                ''
              )}
              {this.state.buttonType === 0 ? (
                <button className={styles.getYzm} onClick={this.getCode}>
                  获取验证码
                </button>
              ) : this.state.buttonType === 1 ? (
                <button className={styles.getYzm}>
                  {this.state.countdown}秒后可重发
                </button>
              ) : (
                <button
                  className={styles.getYzm}
                  style={{ fontSize: 14 }}
                  onClick={this.getCode}
                >
                  重新获取验证码
                </button>
              )}
            </div>
            <button onClick={this.doBind} className={styles.doBind}>
              确认绑定
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(withRouter(BindPhone));
