import { Component } from 'react';
import { Switch, NavLink, withRouter, Route } from 'react-router-dom';
import styles from './index.module.css';
// import PrivateRoute from '../../components/privateRoute';
import CollectIndex from '../collectIndex';
import CollectInfo from '../collectInfo';
import CollectToy from '../collectToy';
import CollectMaterial from '../collectMaterial';
class TeachDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navList: [
        {
          id: 0,
          navName: '教学仪器',
          route: '/typeList/typeTeachingFacility?type=1'
        },
        {
          id: 1,
          navName: '信息化设备',
          route: '/typeList/typeTeachingFacility/info?type=1'
        },
        {
          id: 2,
          navName: '玩教具',
          route: '/typeList/typeTeachingFacility/toy?type=1'
        },
        {
          id: 3,
          navName: '配套材料',
          route: '/typeList/typeTeachingFacility/material?type=1'
        }
      ] // nav list
    };
  }
  componentDidMount() {
    // this.props.hideFather();
  }
  componentWillUnmount() {
    // this.props.hideFather();
  }
  render() {
    let { path } = this.props.match;
    return (
      <div className={styles.wrapChild}>
        <div className={styles.inner}>
          <div className={styles.fixedTop}>
            <div className={styles.secondNav}>
              {this.state.navList.map((item) => (
                <NavLink
                  key={item.id}
                  exact
                  activeClassName={styles.selectTab}
                  className={styles.navList}
                  to={item.route}
                >
                  {item.navName}
                </NavLink>
              ))}
            </div>
          </div>
          <div className={styles.box}>
            <Switch>
              <Route exact path={`${path}`}>
                <CollectIndex />
              </Route>
              <Route path={`${path}/info`}>
                <CollectInfo />
              </Route>
              <Route path={`${path}/toy`}>
                <CollectToy />
              </Route>
              <Route path={`${path}/material`}>
                <CollectMaterial />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TeachDevice);
