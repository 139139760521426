const getUrl = () => window.location.pathname;
const getSearch = () => window.location.search;
// 校验手机号
const verifyPhone = (phone) => {
  if (!phone) return false;
  let reg =
    /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
  if (reg.test(phone)) {
    return true;
  } else {
    return false;
  }
};
// 获取设备号
const getDevice = () => {
  let device = window.navigator.userAgent;
  return device;
};
// /**
//  * @author trsoliu
//  * @date  2019-12-05
//  * @description 校正windows页面在系统进行缩放后导致页面被放大的问题，通常放大比例是125%、150%
//  * **/

class DevicePixelRatio {
  //获取系统类型
  _getSystem() {
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (isMac) {
      return true;
    }
    //现只针对windows处理，其它系统暂无该情况，如有，继续在此添加
    if (agent.indexOf('windows') >= 0) {
      return true;
    }
  }
  //获取页面缩放比例
  //	_getDevicePixelRatio() {
  //		let t = this;
  //	}
  //监听方法兼容写法
  _addHandler(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent('on' + type, handler);
    } else {
      element['on' + type] = handler;
    }
  }
  //校正浏览器缩放比例
  _correct() {
    //页面devicePixelRatio（设备像素比例）变化后，计算页面body标签zoom修改其大小，来抵消devicePixelRatio带来的变化。
    document.getElementsByTagName('body')[0].style.zoom =
      (1 / window.devicePixelRatio) * (1920 / 1757);
  }
  //监听页面缩放
  _watch() {
    let t = this;
    t._addHandler(window, 'resize', function () {
      //注意这个方法是解决全局有两个window.resize
      //重新校正
      t._correct();
    });
  }
  //初始化页面比例
  init() {
    let t = this;
    if (t._getSystem()) {
      //判断设备，目前只在windows系统下校正浏览器缩放比例
      //初始化页面校正浏览器缩放比例
      t._correct();
      //开启监听页面缩放
      t._watch();
    }
  }
}
// 获取登录localStory是否过期
const getToken = () => {
  let myToken =
    localStorage.getItem('token') || document.cookie.split('userToken=')[1];
  if (!myToken) {
    return '';
  }
  let nowTimestamp = new Date().getTime() + 86400000 * 7;
  let tokenStr = myToken + ',' + nowTimestamp;
  let tokenArr = tokenStr.split(',');
  let nowTimeStamp = new Date().getTime();
  let token = tokenArr[0];
  // 当前时间大于了设置的时间 说明过期了
  if (nowTimeStamp > tokenArr[1]) {
    console.log('token已过期，请重新登录');
    token = '';
    localStorage.removeItem('token');
    document.cookie = 'userToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
  if (token.indexOf(';') !== -1) {
    token = token.split(';')[0];
  }
  return token;
};

export {
  getUrl,
  getSearch,
  verifyPhone,
  getDevice,
  getToken,
  DevicePixelRatio
};
